import { put, select } from "redux-saga/effects"
import { AppActions } from "../actions"
import { APIException } from "../../lib/api/fetch";


function* toggleLoading ({loading=true, inAppLoading=false}) {
    // Get status
    let status;
    if (loading) status = yield select(state => state.app.loading);
    else if (inAppLoading) status = yield select(state => state.app.inAppLoading);

    // Switch
    if (loading) yield put(AppActions.loading(!status));
    else if (inAppLoading) yield put(AppActions.inAppLoading(!status));

    return
}

function* execute({loading=true, operation, save, onError, inAppLoading=false}) {
    yield toggleLoading({loading: inAppLoading ? false : loading, inAppLoading})

    let res;

    try{
        res = yield operation()

    } catch(e){
        if (!onError) {
            if (e instanceof APIException) {
                yield toggleLoading({loading: inAppLoading ? false : loading, inAppLoading})

                yield put(AppActions.addNotification({
                    isError: true,
                    message: e.res.data.message
                }))

                return
            }
            
            else throw e
        }

        else {
            onError(e);
            yield toggleLoading({loading: inAppLoading ? false : loading, inAppLoading})
            return
        };
    }

    if (!!save) yield save(res)

    yield toggleLoading({loading: inAppLoading ? false : loading, inAppLoading})
}

export default execute;
