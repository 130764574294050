import { put, takeEvery, select } from "redux-saga/effects";
import { ReportingActions } from '../actions'
import Call from "../../lib/api/fetch";
import execute from './execute';


function* executeGetReportingTopics() { 
	yield execute({
		operation: async () => await Call("/reporting/topics", "GET"),
		save: function* ({ data }) {
			yield put(ReportingActions.saveReportingTopics(data))
		}
	})
}

function* executeCreateReportingTopics({ payload }) {
	yield execute({
		operation: async () => await Call("/reporting/topics", "POST", payload),
		save: function* ({ data }) {
			const topics = yield select(state => state.reporting.topics);
			yield put(ReportingActions.saveReportingTopics([...topics, ...data]));
		}
	})
}

function* executeUpdateReportingTopics({ payload }){
	yield execute({
		operation: async () => await Call("/reporting/topics/" + payload.id, "PUT", payload),
		save: function* ({ data }) {
			const topics = yield select(state => state.reporting.topics);
			yield put(ReportingActions.saveReportingTopics(topics.map(topic => topic.id === payload.id ? data : topic)))
		}
	})
}

function* executeDeleteRepotingTopic({ payload }){
	yield execute({
		operation: async () => await Call("/reporting/topics/" + payload, "DELETE"),
		save: function* () {
			const topics = yield select(state => state.reporting.topics);
			yield put(ReportingActions.saveReportingTopics(topics.filter(topic => topic.id !== payload)))
		}
	})
}

function* executeGetDistributionList(){
	yield execute({
		operation: async () => await Call("/reporting/distributionList"),
		save: function* ({ data }) {
			yield put(ReportingActions.saveDistributionList(data))
		}
	})
}

function* executeCreateReportingDistributionList({ payload }) {
	yield execute({
		operation: async () => await Call("/reporting/distributionList", "POST", payload),
		save: function* ({ data }) {
			const distributionList = yield select (state => state.reporting.distributionList);
			yield put(ReportingActions.saveDistributionList([...distributionList, ...data]))
		}
	})
}

function* executeUpdateDistributionList({ payload }) {
	yield execute({
		 // Only accessLevel can be updated
		operation: async () => await Call("/reporting/distributionList/" + payload.id, "PUT", {accessLevel: payload.accessLevel}),
		save: function* ({ data }) {
			const distributionList = yield select (state => state.reporting.distributionList);
			yield put(ReportingActions.saveDistributionList(distributionList.map(dl => dl.id === payload.id ? data : dl )))
		}
	})
}

function* executeDeleteDistributionList({ payload }){
	yield execute({
		operation: async () => await Call("/reporting/distributionList/" + payload, "DELETE"),
		save: function* ({ data }) {
			const distributionList = yield select (state => state.reporting.distributionList);
			yield put(ReportingActions.saveDistributionList(distributionList.filter(dl => dl.id !== payload)))
		}
	})
}

function* executeGetReports(){
	yield execute({
		operation: async () => await Call('/reporting/reports'),
		save: function* ({ data }){ 
			yield put(ReportingActions.saveReports(data));
		}
	})
}

function* executeCreateReport({ payload }) {
	yield execute({
		operation: async () => await Call('/reporting/reports/upload', "POST", payload),
		save: function* ({ data }){
			
		}
	})
}

function* executeUpdateReport({ payload }){
	const { id, asOf, format, recipients, accessLevel } = payload;

	yield execute({
		operation: async () => await Call("/reporting/reports/" + id, "PUT", { asOf, format, recipients, accessLevel }),
		save: function* ({ data }){ 
			const reports = yield select(state => state.reporting.reports);
			yield put(ReportingActions.saveReports(reports.map(report => report.id === id ? data : report)));
		}
	})
}

function* executeDeleteReport({ payload }){
	yield execute({
		operation: async () => await Call('/reporting/reports/' + payload, 'DELETE'),
		save: function* ({ data }){
			const reports = yield select(state => state.reporting.reports);
			// using data object confirms delete
			yield put(ReportingActions.saveReports(reports.filter(report => report.id !== data.id)));  
		}
	})
}


export default function* ReportingWatcher() {
	// Topics
	yield takeEvery(ReportingActions.types.GET_REPORTING_TOPICS, executeGetReportingTopics);
	yield takeEvery(ReportingActions.types.CREATE_REPORTING_TOPIC, executeCreateReportingTopics)
	yield takeEvery(ReportingActions.types.UPDATE_REPORTING_TOPIC, executeUpdateReportingTopics);
	yield takeEvery(ReportingActions.types.DELETE_REPORTING_TOPIC, executeDeleteRepotingTopic);

	// DL
	yield takeEvery(ReportingActions.types.GET_REPORTING_DISTRIBUTION_LIST, executeGetDistributionList);
	yield takeEvery(ReportingActions.types.CREATE_DISTRIBUTION_LIST, executeCreateReportingDistributionList)
	yield takeEvery(ReportingActions.types.DELETE_DISTRIBUTION_LIST, executeDeleteDistributionList);
	yield takeEvery(ReportingActions.types.UPDATE_DISTRIBUTION_LIST, executeUpdateDistributionList);

	// Reports
	yield takeEvery(ReportingActions.types.GET_REPORTS, executeGetReports);
	yield takeEvery(ReportingActions.types.CREATE_REPORT, executeCreateReport);
	yield takeEvery(ReportingActions.types.UPDATE_REPORT, executeUpdateReport);
	yield takeEvery(ReportingActions.types.DELETE_REPORT, executeDeleteReport);
};
