import Button from './button'
import Checkbox from './checkBox'
import DataCard from './dataCard'
import DatetimePicker from './datetimePicker'
import FileUpload from './fileUpload'
import FilterBar from './filterBar'
import Form, { formField, formDateTimeFmt } from './form'
import Header from './header'
import InputBox from './inputBox'
import InputBoxList from './inputBoxList'
import { IntervalCurveCard } from './curveCard'
import Layout from './layout'
import Modal from './modal'
import Notification from './notification'
import Page from './page'
import SelectBox from './selectBox'
import SearchBar from './searchBar'
import SideBar from './sidebar'
import Spinner from './spinner'
import Table from './table'
import Tabs from './tabs'

const DeleteModal = ({deleteMode=false, setDeleteMode, onDelete, warningMessage, title='Confirm Delete'}) => {
    const actions = [
        {
            children: 'Cancel',
            onClick: () => setDeleteMode(false),
            buttonType: 'secondaryButton'
        },
        {
            children: 'Delete',
            onClick: onDelete
        }
    ]

    const handleCancel = e => setDeleteMode(false)

    return <Modal title={title} show={deleteMode} cancel={handleCancel} actions={actions}>
        <div className="deleteMessage">{warningMessage}</div>
    </Modal>
}

export {
    Button,
    Checkbox,
    DataCard,
    DatetimePicker,
    DeleteModal,
    FileUpload,
    FilterBar,
    Form,
    formDateTimeFmt,
    formField,
    Header,
    InputBox,
    InputBoxList,
    IntervalCurveCard,
    Layout,
    Notification,
    Modal,
    Page,
    SelectBox,
    SideBar,
    Spinner,
    Table,
    Tabs
}