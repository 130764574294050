import { ProductTypes, Products, DataSources } from '../../modules/market/commodityProducts'
import {ProductId} from '../../modules/market/commodityProducts/product_id'


const CommodityProductRoutes = [
    {
        path: 'commodityProducts',
        component: Products,
        subRoutes: [
            {
                path: 'dataSources',
                component: DataSources
            },
            {
                path: ':productId',
                component: ProductId
            }
        ]
    },
    {
        path: 'commodityProductTypes',
        component: ProductTypes
    },
]

export default CommodityProductRoutes