import { all } from 'redux-saga/effects'
import { combineWatchers } from 'redux-saga-combine-watchers';

import AdminWatcher from './admin'
import CommodityProductsWatcher from './commodityProducts'
import MoveToBillWatcher from './moveToBill'
import ReportingWatcher from './reporting'
import UtilitiesWatcher from './utilities'
import WeatherWatcher from './weather'


export default function* rootSaga() {
  yield all(
    combineWatchers(
      AdminWatcher,
      CommodityProductsWatcher,
      MoveToBillWatcher,
      ReportingWatcher,
      UtilitiesWatcher,
      WeatherWatcher
    )
  )
}
