import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ReportingPrivileges, Icons } from "../../lib/constants"
import { AdminActions, AppActions, ReportingActions} from "../../redux/actions"
import { useNavigate } from "react-router-dom"
import { Button, DatetimePicker, DeleteModal, FileUpload, Form, Modal, SelectBox, Spinner, Table, formDateTimeFmt } from "../../components"
import { ReportingTopics } from "./topics"
import { formatDate } from "../../lib/helpers"
import Call, { APIException } from "../../lib/api/fetch"
import download from "downloadjs"
import './reports.scss';


const Formats = {
    1: 'CSV',
    2: 'XLSX',
    3: 'PDF',
    4: 'IMG',
    5: 'DOCX'
}

const ReportForm = ({report={}, handleSubmit, cancelEdit, topics=[], users=[] }) => {
    const [values, setValues] = useState(report);

    const [application, setApplication] = useState(
        report.topicId 
            ? (topics.filter(t => t.id === report.topicId)[0] || {}).application || 0
            : 0
    )

    const [accessLevels, setAccessLevels] = useState(
        report.topicId 
            ? (topics.filter(t => t.id === report.topicId)[0] || {}).accessLevels || []
            : []
    )

    const [errors, setErrors] = useState({})

    const handleTopicChange = topicId => {
        const topic = topics.filter(t => t.id === topicId)[0]

        if(!topic) topicId = null

        setValues({...values, topicId, accessLevel: null, recipients: []})
        setAccessLevels(!topicId ? [] : topics.filter(t => t.id === topicId)[0].accessLevels || [])
        setErrors({})
    }

    const handleApplicationChange = app => {
        if (!Object.getOwnPropertyNames(ReportingTopics).includes(app)) app = 0
        else app = parseInt(app)
        setApplication(app)
        handleTopicChange(null)
        setErrors({})
    }

    const fields = [
        {
            fieldName: 'application',
            label: 'Application',
            type: "select",
            options: Object.getOwnPropertyNames(ReportingTopics).map(t => ({
                id: parseInt(t),
                name: ReportingTopics[t]
            })),
            singleSelectOnly: true,
            value: application,
            readOnly: !!report.id,
            onChange: ({ target : { value }}) => handleApplicationChange(value),
            placeholder: 'Unknown'
        },
        {
            fieldName: 'topicId',
            label: 'Topic',
            type: 'select',
            options: topics.filter(t => t.application === application).map(t => ({id: t.id, name: t.name })),
            singleSelectOnly: true,
            value: values.topicId || '',
            placeholder: 'Select report topic',
            readOnly: !!report.id,
            hidden: application === 0,
            onChange: ({target: { value }}) => handleTopicChange(value)
        },
        // Automatically detect format
        // {
        //     fieldName: 'format',
        //     label: 'Format',
        //     type: 'select',
        //     options: Object.getOwnPropertyNames(Formats).map(fmt => ({id: parseInt(fmt), name: Formats[fmt]})),
        //     singleSelectOnly: true,
        //     value: values.format == null ? 0 : values.format,
        //     placeholder: 'Select report file format',
        //     hidden: !values.topicId,
        //     onChange: ({target: { value }}) => setValues({...values, format: value === 'Select report file format' ? 0 : parseInt(value)})
        // },
        {
            fieldName: 'asOf',
            type: 'date',
            label: 'As Of',
            value: values.asOf ? new Date(values.asOf) : new Date(),
            hidden: !values.topicId,
            onChange: ({target : { value }}) => setValues({...values, asOf: value})
        },
        {
            fieldName: 'fileData',
            type: 'custom',
            label: 'File' + (values.fileName ? ": " + values.fileName : ""),
            hidden: !values.topicId,
            element: <FileUpload 
                onChange={({ target : { files }}) => {
                    const fileName  = files[0].name
                    const ext = fileName.split(".")[1];
                    const fmt = Object.getOwnPropertyNames(Formats).filter(f => Formats[f] === ext.toUpperCase())[0];

                    if (!fmt){
                        setErrors({...errors, fileData: 'Invalid file extension'})
                        return
                    }

                    const reader = new FileReader();
                    reader.onload = () => {
                        setValues({
                            ...values, 
                            fileName, 
                            fileData: reader.result.split('base64,')[1],
                            format: parseInt(fmt)
                        })
                    };
                    
                    reader.readAsDataURL(files[0]);

                    setErrors({...errors, fileData: null})
                }}
                label=""
                value=""
            />
        },
        {
            type: 'error',
            fieldName: 'fileDataError',
            hidden: !errors.fileData,
            value: errors.fileData
        },
        {
            fieldName: 'accessLevel',
            label: 'Access Level',
            type: 'select',
            options: accessLevels.map(al => ({id: al, name: al})),
            value: values.accessLevel,
            singleSelectOnly: true,
            hidden: accessLevels.length === 0,
            onChange: ({ target : { value }}) => setValues({...values, accessLevel: value}),
            placeholder: 'Select access level'
        },
        {
            fieldName: 'recipients',
            label: 'Recipients',
            options: users.map(u => ({id: u.id, name: u.firstName + ' ' + u.lastName})),
            type: 'select',
            value: values.recipients || [],
            hidden: !values.topicId,
            onChange: ({target : { value }}) => setValues({...values, recipients: [...(values.recipients || []), value]}),
            onDelete: id => setValues({...values, recipients: values.recipients.filter(u => u !== id)}),
            placeholder: 'Add recipient'
        }
    ]

    const onSubmit = e => {
        e.preventDefault();
        const errors_obj = {}
        if (application === 0) errors_obj.application = 'Select appropriate application';
        if (!values.topicId) errors_obj.topicId = 'Enter valid topic ID';
        if (accessLevels.length > 0 && (!values.accessLevel || !accessLevels.includes(values.accessLevel))) errors_obj.accessLevel = 'Select valid access level'

        if (Object.getOwnPropertyNames(errors_obj).length > 0) {
            setErrors(errors_obj)
            return
        }

        handleSubmit(values)
        cancelEdit(e)
    }

    const formActions = [
        { buttonType: 'secondaryButton', text: 'Cancel', onClick: cancelEdit},
        { text: values.id ? 'Update': 'Create', type: 'submit'}

    ]

    return <Form fields={fields} errors={errors} onSubmit={onSubmit} actions={formActions} canEdit={true} />
}

const downloadReport = async ({ report, dispatch, topics=[] }) => {
    dispatch(AppActions.loading(true))

    // Create new fileName
    const topic = topics.filter(t => t.id === report.topicId)[0]

    const fileDate = !!report.asOf ? report.asOf : report.createdAt;

    const fileName = (
        topic.name.replaceAll(' ', '_') + '_' + 
        formDateTimeFmt(new Date(fileDate)).replaceAll(':', '').replaceAll('-', '').replaceAll('T', '') + '.' + 
        Formats[report.format].toLowerCase()
    )

    const { data } = await Call("/reporting/reports/" + report['id'])

    download(atob(data.fileData), fileName)

    dispatch(AppActions.loading(false))
}

export default function Module({ canEdit, setCanEdit, showModal, cancelModal }){
    const [reports, setReports] = useState([])
    const [search, setSearch] = useState({
        application: 0,
        topicId: null,
        fromDate: null,
        toDate: null,
        loading: false
    })

    const [wait, setWait] = useState(false);

    const topics = useSelector(state => state.reporting.topics);
    const users = useSelector(state => state.admin.users);
    const me = useSelector(state => state.app.me);

    const [updateReport, setUpdateReport] = useState(null)
    const [deleteReport, setDeleteReport] = useState(null)

    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {
        if(!!me) {
            let authorized = me.claims.filter(c => c.name === ReportingPrivileges.VIEW_REPORTS).length > 0

            if (!authorized) navigate('/')

            else{
                setCanEdit(me.claims.filter(c => c.name === ReportingPrivileges.CREATE_EDIT_REPORTS).length > 0)

                if (!topics) dispatch(ReportingActions.getReportingTopics());

                else if (!users) dispatch(AdminActions.Users.getUsers());
            }
        }
    })

    const getColumnStyle = width => ({"textWrap": "wrap", "width": width });

    const columns = [
        {key: 'application', title: 'Application', styles: getColumnStyle('20%')},
        {key: 'topic', title: 'Topic', styles: getColumnStyle('20%')},
        {key: 'asOfDate', title: 'As Of', styles: getColumnStyle('15%')},
        {key: 'formatEnum', title: 'Format', styles: getColumnStyle('8%')},
        {key: 'accessLevel', title: 'Access Level', styles: getColumnStyle("10%")},
        {key: 'recipientsStr', title: 'Recipients', styles: getColumnStyle()},
        {key: 'download', title: '', styles: getColumnStyle('25px')}
    ]

    if (canEdit) columns.push({key: 'delete', title: '', styles: getColumnStyle('25px')})

    const searchReports = async () => {
        setWait(true)

        let res

        try{
            res = await Call('/reporting/reports', "GET", {}, {}, {
                "topicId": search.topicId,
                "fromDate": search.fromDate,
                "toDate": search.toDate
            })
        } catch (e){
            if (e instanceof APIException){
                console.log(e.res.data)
            }
            else throw e
        }
        
        const { data } = res;

        setReports(data)

        setWait(false)

        // setTimeout(() => {
        //     setWait(false)
        // }, 3000)
    }

    return <div className="reportsContainer flex-column">
        <div className="flex reportsFilter">
            <div className="reportsFilterField">
                <SelectBox
                    options={Object.getOwnPropertyNames(ReportingTopics).map(t => ({id: t, name: ReportingTopics[t]}))}
                    selected={[{id: search.application}]}
                    placeholder={'Undefined'}
                    onChange={({target: { value }}) => setSearch({
                        ...search,
                        topicId: null,
                        fromDate: null,
                        toDate: null,
                        application: value === 'Select application' ?  0 : parseInt(value)
                    })}
                    singleSelectOnly={true}
                    label={'Application'}
                />
            </div>
            <div className="reportsFilterField">
                <SelectBox 
                    placeholder={'Select topic'}
                    selected={[{id: search.topicId}]}
                    options={search.application ? topics.filter(t => t.application === search.application) : []}
                    disabled={search.application === 0}
                    onChange={({target: { value }}) => setSearch({...search, topicId: value === 'Select topic' ? null : value})}
                    singleSelectOnly={true}
                    label={'Topic'}
                />
            </div>
            <div className="reportsFilterField">
                <DatetimePicker 
                    value={search.fromDate || ''} 
                    type="date" 
                    label={'From Date'} 
                    onChange={({ target: { value }}) => setSearch({...search, fromDate: value })}
                />
            </div>
            <div className="reportsFilterField">
                <DatetimePicker 
                    value={search.toDate || ''} 
                    type="date" 
                    label={'To Date'}
                    onChange={({ target: { value }}) => setSearch({...search, toDate: value })}
                />
            </div>
            <div className="reportsFilterField">
                {/* Label present here to provide padding */}
                <label className="label">&nbsp;</label> 
                <Button onClick={searchReports} disabled={search.topicId === null} className={"fullWidth"}>Search</Button>
            </div>
        </div>
        {
            wait 
                ? <div className="flex justifyCenter" style={{flex: 1}}>
                    <Spinner size="l" />
                </div>
                : <Table searchBox={false} columns={columns} data={(reports || []).map(report => {
                    const topic = (topics || []).filter(topic => topic.id === report.topicId)[0] || {};
                    let application = ReportingTopics[topic.application];
        
                    let recipients = (report.recipients || []).map(r => users.filter(u => u.id === r)[0])
        
                    return {
                        ...report,
                        application,
                        topic: topic.name,
                        formatEnum: Formats[report.format],
                        accessLevel: report.accessLevel ? report.accessLevel : 'All',
                        recipientsStr: recipients.map(r => r.firstName + " " + r.lastName).join(', '),
                        asOfDate: report.asOf ? formatDate(new Date(report.asOf)) : formatDate(new Date(report.createdAt)),
                        download: <div style={{fontSize: '20px', cursor: 'pointer'}} onClick={async (e) => {
                            e.stopPropagation()
                            await downloadReport({report, dispatch, topics })
                        }}>{Icons.DOWNLOAD}</div>,
                        delete :<div style={{fontSize: '20px'}} onClick={(e) => {
                            e.stopPropagation()
                            setDeleteReport(report)
                        }}>{Icons.DELETE}</div>
                    }
                })}
                // Let's not allow report updating for the moment
                // rowOnClick={!canEdit ? null : ({e, row}) => setUpdateReport({
                //     id: row.id,
                //     topicId: row.topicId,
                //     format: row.format,
                //     accessLevel: row.accessLevel,
                //     asOf: row.asOf,
                //     recipients: row.recipients
                // })}
                />
        }        
        <Modal title='Update Report' show={!!updateReport} cancel={() => setUpdateReport(null)}>
            <ReportForm 
                report={updateReport}
                canEdit={canEdit}
                cancelEdit={() => setUpdateReport(null)}
                handleSubmit={values => dispatch(ReportingActions.updateReport(values))}
                topics={topics}
                users={users}
            />
        </Modal>
        <DeleteModal 
            deleteMode={!!deleteReport}
            setDeleteMode={() => setDeleteReport(null)}
            onDelete={() => dispatch(ReportingActions.deleteReport(deleteReport.id))}
            warningMessage={deleteReport && `Are you sure you want to delete the report? This will delete the file from the system`}
        />
        <Modal title='Upload Report Subscriber' show={showModal} cancel={cancelModal}>
            <ReportForm 
                handleSubmit={values => dispatch(ReportingActions.createReport(values))} 
                cancelEdit={cancelModal}
                topics={topics}
                users={users}
            />
        </Modal>
    </div>
}
