import { useState, useEffect } from "react"
import { SelectBox, InputBox, Form } from "../../../../components"
import { CPPriceTypes, CPPriceTypeEnums, Icons } from "../../../../lib/constants"
import './index.scss'
import { CommodityProductActions } from "../../../../redux/actions"
import { useDispatch } from "react-redux"


const ALLOWED_PRICE_TYPES = [
    CPPriceTypes.Open,
    CPPriceTypes.Close,
    CPPriceTypes.Settle
]


const DataSourceCode = ({ values={}, priceTypes, sources, allowedCombinations, handleChange, handleDelete, canEdit=true}) => {
    const priceTypeOptions = !!values.priceType 
        ? priceTypes
        : priceTypes.filter(pt => allowedCombinations.filter(ac => ac.priceType === pt.id).length > 0)
    
    let allowedSource = allowedCombinations.filter(ac => ac.priceType === values.priceType).map(ac => ac.sourceId)

    const sourceOptions = !!values.sourceId 
        ? sources
        : sources.filter(src => allowedSource.includes(src.id))

    return priceTypeOptions.length > 0 
    ? <tr className="dsctRow">
        <td><SelectBox 
            options={priceTypeOptions}
            singleSelectOnly={true}
            selected={priceTypeOptions.filter(pt => pt.id === values.priceType)}
            onChange={({target: {value}}) => handleChange({...values, priceType: parseInt(value)})}
            disabled={!!values.priceType}
        /></td>
        <td>
            {values.priceType
                ? <SelectBox 
                    options={sourceOptions}
                    singleSelectOnly={true}
                    selected={sourceOptions.filter(s => s.id === values.sourceId )}
                    onChange={({target: { value }}) => handleChange({...values, sourceId: value})}
                    disabled={!!values.sourceId }
                />
                : <></>
            }
        </td>
        <td>
            {values.sourceId 
                ? <InputBox 
                    value={values.symbol || ''} 
                    onChange={({target: { value }}) => handleChange({...values, symbol: value})} 
                    textAlign="center"
                    readOnly={!canEdit}
                /> 
                : <></>
            }
        </td>
        {canEdit 
            ? <td>
                {values.priceType ? <div onClick={() => handleDelete({
                        sourceId: values.sourceId, 
                        priceType: values.priceType
                    })} className="dsctrCancel">
                    {Icons.CANCEL}
                </div> :<></>}
            </td>
            : <></>
        }
    </tr>
    : <></>
}


const DataSourceCodes = ({ product, canEdit, dataSources }) => {
    const dispatch = useDispatch();

    let codes = JSON.parse(JSON.stringify(product.codes || []))

    const [values, setValues] = useState(codes)

    const [allowedCombinations, setAllowedCombinations] = useState([])

    const [changed, setChanged] = useState(false)

    const possibleCombinations = ALLOWED_PRICE_TYPES.map(
        pt => (dataSources || []).map(ds => ({
            sourceId:ds.id,
            priceType: pt
        }))
    ).flat(1)

    useEffect(() => {
        setAllowedCombinations(
            possibleCombinations.filter(
                comb => values.filter(code => 
                    comb.sourceId === code.sourceId && comb.priceType === code.priceType
                ).length === 0
            ) 
        )
    }, [product.codes])

    const changeAllowedCombinations = newValues => setAllowedCombinations(
        possibleCombinations.filter(
            combination => newValues.filter(code => 
                combination.sourceId === code.sourceId && combination.priceType === code.priceType
            ).length === 0
        ) 
    )

    const handleChange = ({sourceId=null, symbol=null, priceType=null}) => {
        let newValues = JSON.parse(JSON.stringify(values))

        let priceTypeCodes = newValues.filter(value => value.priceType === priceType)

        if (priceTypeCodes.length === 0){
            newValues = [...newValues, {sourceId, symbol, priceType}]
            setValues(newValues)
            changeAllowedCombinations(newValues)
            return   
        }

        let ptSourceCode = priceTypeCodes.filter(ptsc => ptsc.sourceId === sourceId)[0]

        if (!ptSourceCode){
            let nullSourceId = newValues.filter(val => val.priceType === priceType && val.sourceId === null)[0]

            if (!!nullSourceId) {
                if (!sourceId) return 

                newValues = newValues.map(value => {
                    if (value.priceType === priceType && value.sourceId === null){
                        value.sourceId = sourceId
                        value.symbol = symbol
                    }
                    return value
                })
            }
            else newValues = [...newValues, {priceType, sourceId, symbol}]
                        
            setValues(newValues)
            changeAllowedCombinations(newValues)
            return
        }
        
        newValues = newValues.map(value => {
            if (value.priceType === priceType && value.sourceId === sourceId) value.symbol = symbol
            return value
        })

        setValues(newValues)
        changeAllowedCombinations(newValues)
        setChanged(true)
    }

    const handleDelete = ({sourceId, priceType}) => {
        const newValues = values.filter(code => (!(code.sourceId === sourceId && code.priceType === priceType)))

        setValues(newValues)

        changeAllowedCombinations(newValues)
    }

    const updateCodes = () => {
        // console.log(values.filter(value => value.priceType != null && value.sourceId !=  null && value.symbol != null ))
        // return
        dispatch(CommodityProductActions.Products.updateProductCodes({
            productId: product.id,
            codes: values.filter(value => value.priceType != null && value.sourceId !=  null && value.symbol != null )
        }))
    }

    return <div className="dataSourceCodesContainer">
        <table className="fullWidth dscTable">
            <thead>
                <tr>
                    <th>Price Type</th>
                    <th>Source</th>
                    <th>Symbol</th>
                    {canEdit ? <th></th> : <></>}
                </tr>
            </thead>
            <tbody>
                {values.map(({sourceId, priceType, symbol}) => <DataSourceCode 
                    key={`${sourceId}_${priceType}`}
                    values={{sourceId, priceType, symbol}}
                    priceTypes={ALLOWED_PRICE_TYPES.map(pt => ({id: pt, name: CPPriceTypeEnums[pt]}))}
                    sources={(dataSources || []).map(ds => ({id: ds.id, name: ds.code}))}
                    allowedCombinations={allowedCombinations}
                    handleChange={handleChange}
                    handleDelete={handleDelete}
                    canEdit={canEdit}
                />)}
                {canEdit 
                    ? <DataSourceCode 
                        priceTypes={ALLOWED_PRICE_TYPES.map(pt => ({id: pt, name: CPPriceTypeEnums[pt]}))}
                        sources={(dataSources || []).map(ds => ({id: ds.id, name: ds.code}))}
                        allowedCombinations={allowedCombinations}
                        handleChange={handleChange}
                        handleDelete={handleDelete}
                        canEdit={canEdit}
                    />
                    : <></>
                }
            </tbody>
        </table>
        <Form fields={[]} canEdit={canEdit} actions={[{text: 'Update', onClick: updateCodes}]}/>
    </div>
}


export default DataSourceCodes;
