import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ReportingPrivileges, CommodityEnums, Icons } from "../../lib/constants"
import { AdminActions, ReportingActions} from "../../redux/actions"
import { useNavigate } from "react-router-dom"
import { DeleteModal, Form, Modal, Table } from "../../components"
import { ReportingTopics } from "./topics"


const Subscriber = ({subscriber={}, canEdit=true, handleSubmit, cancelEdit, topics=[], users=[] }) => {
    const [application, setApplication] = useState(
        subscriber.topicId 
            ? (topics.filter(t => t.id === subscriber.topicId)[0] || {}).application || 0
            : 0
    )

    const [accessLevels, setAccessLevels] = useState(
        subscriber.topicId 
            ? (topics.filter(t => t.id === subscriber.topicId)[0] || {}).accessLevels || []
            : []
    )

    const [values, setValues] = useState(subscriber)

    const [errors, setErrors] = useState({})

    const handleTopicChange = topicId => {
        const topic = topics.filter(t => t.id === topicId)[0]

        if(!topic) topicId = null

        setValues({...values, topicId, accessLevel: null, userId: null})
        setAccessLevels(!topicId ? [] : topics.filter(t => t.id === topicId)[0].accessLevels || [])
        setErrors({})
    }

    const handleApplicationChange = app => {
        if (!Object.getOwnPropertyNames(ReportingTopics).includes(app)) app = 0
        else app = parseInt(app)
        setApplication(app)
        handleTopicChange(null)
        setErrors({})
    }

    const fields = [
        {
            fieldName: 'application',
            label: 'Application',
            type: "select",
            options: Object.getOwnPropertyNames(ReportingTopics).map(t => ({
                id: parseInt(t),
                name: ReportingTopics[t]
            })),
            singleSelectOnly: true,
            value: application,
            readOnly: !!subscriber.id || !canEdit,
            onChange: ({ target : { value }}) => handleApplicationChange(value),
            placeholder: 'Unknown'
        },
        {
            fieldName: 'topicId',
            label: 'Topic',
            type: 'select',
            options: topics.filter(t => t.application === application).map(t => ({
                id: t.id,
                name: t.name
            })),
            singleSelectOnly: true,
            value: values.topicId || '',
            placeholder: 'Select report topic',
            readOnly: !!subscriber.id || !canEdit,
            hidden: application === 0,
            onChange: ({target: { value }}) => handleTopicChange(value)
        },
        {
            fieldName: 'userId',
            label: 'User',
            type: 'select',
            options: users.map(u => ({id: u.id, name: u.firstName + ' ' + u.lastName})),
            value: values.userId || '',
            singleSelectOnly: true,
            readOnly: !!subscriber.id || !canEdit,
            onChange: ({ target : { value }}) => setValues({...values, userId: value === 'Select user' ? null : value}),
            hidden: !values.topicId,
            placeholder: 'Select user'
        },
        {
            fieldName: 'accessLevel',
            label: 'Access Level',
            type: 'select',
            options: accessLevels.map(al => ({id: al, name: al})),
            value: values.accessLevel,
            singleSelectOnly: true,
            hidden: accessLevels.length === 0,
            readOnly: !canEdit,
            onChange: ({ target : { value }}) => setValues({...values, accessLevel: value}),
            placeholder: 'Select access level'
        }
    ]

    const onSubmit = e => {
        e.preventDefault();
        const errors_obj = {}
        if (application === 0) errors_obj.application = 'Select appropriate application';
        if (!values.topicId) errors_obj.topicId = 'Enter valid topic ID';
        if (!values.userId) errors_obj.userId = 'Enter valid User ID'
        if (accessLevels.length > 0 && (!values.accessLevel || !accessLevels.includes(values.accessLevel))) errors_obj.accessLevel = 'Select valid access level'

        if (Object.getOwnPropertyNames(errors_obj).length > 0) {
            setErrors(errors_obj)
            return
        }

        handleSubmit({...values, delete: null})
        cancelEdit(e)
    }

    const formActions = [
        { buttonType: 'secondaryButton', text: 'Cancel', onClick: cancelEdit},
        { text: values.id ? 'Update': 'Create', type: 'submit'}

    ]

    return <Form fields={fields} errors={errors} onSubmit={onSubmit} actions={formActions} canEdit={canEdit} />
}

export default function Module({ canEdit, setCanEdit, showModal, cancelModal }){
    const topics = useSelector(state => state.reporting.topics)
    const distributionList = useSelector(state => state.reporting.distributionList)
    const users = useSelector(state => state.admin.users)
    const me = useSelector(state => state.app.me)

    const [updateSubscriber, setUpdateSubscriber] = useState(null)
    const [deleteSubscriber, setDeleteSubscriber] = useState(null)

    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {
        if(!!me) {
            let authorized = me.claims.filter(c => c.name === ReportingPrivileges.VIEW_REPORT_DISTRIBUTION_LIST).length > 0

            if (!authorized) navigate('/')

            else{
                setCanEdit(me.claims.filter(c => c.name === ReportingPrivileges.CREATE_EDIT_REPORT_DISTRIBUTION_LIST).length > 0)

                if (!topics) dispatch(ReportingActions.getReportingTopics())

                else if (!distributionList) dispatch(ReportingActions.getDistributionList());

                else if (!users) dispatch(AdminActions.Users.getUsers());
            }
        }
    })

    const getColumnStyle = width => ({"textWrap": "wrap", "width": width });

    const columns = [
        {key: 'application', title: 'Application', styles: getColumnStyle('22%')},
        {key: 'topic', title: 'Topic', styles: getColumnStyle('22%')},
        {key: 'user', title: 'User', styles: getColumnStyle('22%')},
        {key: 'accessLevel', title: 'Access Level', styles: getColumnStyle()},
        {key: 'delete', title: '', styles: getColumnStyle('25px')}
    ]

    return <>
        <Table searchBox={false} columns={columns} data={(distributionList || []).map(subscriber => {
            const topic = (topics || []).filter(x => x.id === subscriber.topicId)[0] || {}
            const user = (users || []).filter(x => x.id === subscriber.userId)[0] || {}

            return {
                ...subscriber,
                application: ReportingTopics[topic.application],
                topic: topic.name,
                appName: ReportingTopics[topic.application],
                user: user.firstName + ' ' + user.lastName,
                comm: CommodityEnums[topic.commodity],
                delete: <div style={{fontSize: '20px'}} onClick={(e) => {
                    e.stopPropagation()
                    setDeleteSubscriber(subscriber)
                }}>{Icons.DELETE}</div>
            }})}
            rowOnClick={({e, row}) => setUpdateSubscriber({
                topicId: row.topicId,
                userId: row.userId,
                accessLevel: row.accessLevel,
                id: row.id,
            })}
        />
        <Modal title='Update Subscriber' show={!!updateSubscriber} cancel={() => setUpdateSubscriber(null)}>
            <Subscriber 
                subscriber={updateSubscriber}
                canEdit={canEdit}
                cancelEdit={() => setUpdateSubscriber(null)}
                handleSubmit={values => dispatch(ReportingActions.updateDistributionList(values))}
                topics={topics}
                users={users}
            />
        </Modal>
        <DeleteModal 
            deleteMode={!!deleteSubscriber}
            setDeleteMode={() => setDeleteSubscriber(null)}
            onDelete={() => dispatch(ReportingActions.deleteDistributionList(deleteSubscriber.id))}
            warningMessage={deleteSubscriber && `Are you sure you want to unsubscribe user from report topic?`}
        />
        <Modal title='Add Subscriber' show={showModal} cancel={cancelModal}>
            <Subscriber 
                handleSubmit={values => dispatch(ReportingActions.createDistributionList(values))} 
                cancelEdit={cancelModal}
                topics={topics}
                users={users}
            />
        </Modal>
    </>
}
