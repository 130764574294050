import { useState } from "react"
import { CommodityProductActions } from "../../../../redux/actions"
import { CPPriceTypeEnums, CPPriceTypes, Icons } from "../../../../lib/constants"
import { SelectBox, Form, DataCard, DeleteModal } from "../../../../components"
import { useDispatch } from "react-redux"


const ALLOWED_PRICE_TYPES = [CPPriceTypes.Close, CPPriceTypes.Open]

const processModeOptions = [{id: 1, name: 'Auto'}, {id: 2, name: 'Synthetic'}]

const operations = [{id: '+', name: '+'}, {id: '-', name: '-'}]

const ExpressionBuilder = ({expression, products, handleChange, canEdit}) => {

    const handleExpressionChange = ({idx, operator, productId=null}) => {
        // Don't do anything if operator is invalid
        if (!operations.map(o => o.id).includes(operator)) return
        // Add if new
        if (idx >= expression.length) handleChange([...expression, {operator, productId}])
        // Replace if existing
        else handleChange(expression.map((exp, edx) => edx === idx ? {operator, productId} : exp)) 
    }

    // 
    const handleDelete = ({idx}) => handleChange(expression.filter((exp, e) => e !== idx))

    const productOptions = products.map(prod => ({
        id: prod.id, 
        name: prod.tier1 + ' ' + prod.tier2 + ' (' + prod.productType.code + ')'
    }))

    const ExpressionRow = ({exp, idx}) => {
        return <tr>
            <td className="exprOperator"><SelectBox 
                options={operations}
                singleSelectOnly={true}
                selected={operations.filter(o => o.id === exp.operator)}
                onChange={({ target: { value }}) => handleExpressionChange(
                    {idx, ...exp, operator: value.trim() === "" ? null : value})
                }
                placeholder={''}
                disabled={!canEdit}
            /></td>
            <td>{exp.operator 
                ? <SelectBox 
                    options={productOptions}
                    singleSelectOnly={true}
                    selected={productOptions.filter(o => o.id === exp.productId)}
                    disabled={!canEdit}
                    onChange={({ target: { value }}) => handleExpressionChange({idx, ...exp, productId: value === 'Select product' ? null : value})}
                    placeholder={'Select product'}
                    />
                : <></>
            }</td>
            <td>
                {canEdit && exp.operator && idx >= 0
                    ? <div 
                        style={{ textAlign: "center", fontSize: "20px", cursor: "pointer" }} 
                        onClick={() => handleDelete({idx, ...exp})}>{Icons.CANCEL}
                    </div>
                    : <></>
            }</td>
        </tr>
    }

    return <table className="fullWidth urExpressions">
        <thead>
            <tr>
                <th style={{width: '120px'}}>Operator</th>
                <th>Product</th>
                {canEdit ? <th style={{width: "60px", textAlign: "center"}}></th> : <></>}
            </tr>
        </thead>
        <tbody>
            {(expression || []).map((exp, e) => <ExpressionRow key={e} exp={exp} idx={e} />)}
            {canEdit && (expression.length === 0 || (!!expression.at(-1).productId)) && 
            <ExpressionRow exp={{}} idx={(expression || []).length}/>}
        </tbody>
    </table>
}

const UpdateRuleForm = ({
    product={},
    ruleId=null,
    priceType=0, 
    processMode=0,
    sourceId=null,
    expression=[], 
    onSubmit=({target: { value }}) => console.log(value), 
    canEdit=true,
    dataSources=[],
    products=[]
}) => {
    const [values, setValues] = useState({id: ruleId, priceType, processMode, sourceId, expression})

    const priceTypes = !ruleId
        ? ALLOWED_PRICE_TYPES.filter(x => product.updateRules.filter(r => r.priceType === x).length === 0) 
        : ALLOWED_PRICE_TYPES

    const formFields = [
        {
            fieldName: 'priceType',
            label: 'Price Type',
            type: 'select',
            singleSelectOnly: true,
            value: values.priceType,
            options: priceTypes.map(pt => ({id: pt, name: CPPriceTypeEnums[pt] })),
            onChange: ({ target : { value }}) => setValues({...values, priceType: value === '' ? null : parseInt(value)}),
            placeholder: '',
            readOnly: !!ruleId
        },
        {
            fieldName: 'processMode',
            label: 'Process Mode',
            type: 'select',
            singleSelectOnly: true,
            value: values.processMode,
            onChange: ({target: { value }}) => setValues({...values, processMode: value === 'Off' ? 0 : parseInt(value)}),
            options: processModeOptions,
            placeholder: 'Off'
        },
        {
            fieldName: 'sourceId',
            label: 'Source',
            type: 'select',
            singleSelectOnly: true,
            value: values.sourceId,
            onChange: ({target: { value }}) => setValues({...values, sourceId: value === 'None' ? null : value}),
            options: dataSources.map(ds => ({id: ds.id, name: ds.code})),
            placeholder: 'None',
            hidden: values.processMode !== 1  // Automatic updates only
        },
        {
            fieldName: 'expression',
            label: 'Source',
            type: 'custom',            
            element: <ExpressionBuilder 
                expression={values.expression} 
                products={products} 
                handleChange={expression => setValues({...values, expression})}
                canEdit={canEdit}
            />,
            hidden: values.processMode !== 2  // Synthetic updates only
        }
    ]

    const formActions = [{text: !!ruleId ? 'Update' : "Create", onClick: () => onSubmit(values)}]

    return <Form fields={formFields} actions={formActions}/>
}

const UpdateRuleCard = ({product, ruleId=null, canEdit=true, dataSources=[], products=[], onSubmit, onDelete}) => {
    const [deleteMode, setDeleteMode] = useState(false)

    // If rule ID provided, use that
    const rule = !!ruleId ?  product.updateRules.filter(r => r.id === ruleId)[0] : {};

    const title = !!rule.id ? "Price Type: " + CPPriceTypeEnums[rule.priceType] : 'Add Update Rule'

    const subtitle = !!rule.id ? processModeOptions.filter(p => p.id === rule.processMode)[0].name : null
    
    const cardActions = !!rule.id ? [{ icon: Icons.DELETE, handler: () => setDeleteMode(true)}] : []

    return <>
    <DataCard canEdit={canEdit} title={title} subtitle={subtitle} actions={cardActions} cardSize="s">
        <UpdateRuleForm 
            product={product}
            ruleId={ruleId}
            {...rule}
            canEdit={canEdit}
            products={products}
            dataSources={dataSources}
            onSubmit={onSubmit}
            onDelete={onDelete}
        />
    </DataCard>
    <DeleteModal 
        deleteMode={deleteMode}
        setDeleteMode={setDeleteMode}
        onDelete={onDelete}
        warningMessage={"Delete rule for " + title + " (" + subtitle + ')?'}
    />
    </>
}

const UpdateRules = ({ product={}, canEdit, dataSources, products }) => {
    const dispatch = useDispatch()

    const allowedPriceTypes = ALLOWED_PRICE_TYPES.filter(x => product.updateRules.filter(y => y.priceType === x).length === 0) || []

    return <div className="commProdUpdateRules">
        {product.updateRules.map(rule => <UpdateRuleCard 
            key={rule.id} 
            product={product} 
            ruleId={rule.id} 
            canEdit={canEdit} 
            dataSources={dataSources} 
            products={products}
            onSubmit={values => dispatch(
                CommodityProductActions.Products.updateProductUpdateRule({productId: product.id, updateRule: values})
            )}
            onDelete={() => dispatch(
                CommodityProductActions.Products.deleteProductUpdateRule({productId: product.id, ruleId: rule.id})
            )}
        />)}
        {/* add option */}
        {allowedPriceTypes.length > 0 && <UpdateRuleCard 
            product={product} 
            canEdit={canEdit}
            dataSources={dataSources} 
            products={products}
            onSubmit={values => dispatch(
                CommodityProductActions.Products.createProductUpdateRule({productId: product.id, updateRule: values})
            )}
        />}
    </div>
}

export default UpdateRules;
