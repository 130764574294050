import { put, takeEvery, select } from "redux-saga/effects";
import Call from "../../../lib/api/fetch";
import { CommodityProductActions } from '../../actions'
import execute from "../execute";


export function* executeGetProductTypes(){
    yield execute({
        operation: async () => await Call("/commodityProductTypes", "GET"),
        save: function* (res) {
            yield put(CommodityProductActions.ProductTypes.saveProductTypes(res.data))
        }
    })
}

export function* executeCreateProductType({ payload }){
    const {code, commodity, settlement, instrument, index, timeOfUse, description} = payload;

    yield execute({
        operation: async () => await Call("/commodityProductTypes", "POST", {
             code, commodity, settlement, instrument, index, timeOfUse, description 
            }),
        save: function* (res) {
            const productTypes = yield select(state => state.commodityProducts.productTypes) || [];
            yield put(CommodityProductActions.ProductTypes.saveProductTypes([...productTypes, ...res.data]));
        }
    })
}

export function* executeUpdateProductType({ payload}){
    const { id, settlement, instrument, index, timeOfUse, description } = payload;

    yield execute({
        operation: async () => await Call("/commodityProductTypes/" + id, "PUT", {settlement, instrument, index, timeOfUse, description}),
        save: ({ data }) => {
            const productTypes = select(state => state.commodityProducts.productTypes) || [];
            // Confirm if this works
            put(CommodityProductActions.ProductTypes.saveProductTypes(productTypes.map(pt => pt.id === id ? data : pt)))
        }
    })
}

export function* executeDeleteProductType({ payload }){
    yield execute({
        operation: async () => await Call("/commodityProductTypes/" + payload, "DELETE"),
        save: function* (){
            const productTypes = yield select(state => state.commodityProducts.productTypes) || []

            yield put(CommodityProductActions.ProductTypes.saveProductTypes(productTypes.filter(pt => pt.id !== payload)))
        }
    })
}

export default function* ProductTypesWatcher() {
	yield takeEvery(CommodityProductActions.ProductTypes.types.GET_PRODUCT_TYPES, executeGetProductTypes);
    yield takeEvery(CommodityProductActions.ProductTypes.types.CREATE_PRODUCT_TYPE, executeCreateProductType);
    yield takeEvery(CommodityProductActions.ProductTypes.types.UPDATE_PRODUCT_TYPE, executeUpdateProductType);
    yield takeEvery(CommodityProductActions.ProductTypes.types.DELETE_PRODUCT_TYPE, executeDeleteProductType);
}
