import './index.scss';
import { CommodityProductActions } from '../../../../redux/actions';
import { CPPriceTypeEnums, Icons } from '../../../../lib/constants';
import { downloadCSVFromJson, formatDate } from '../../../../lib/helpers';
import Call from '../../../../lib/api/fetch'
import { FileUpload, Modal, Table} from '../../../../components';
import { useState } from 'react';


const Curves = ({ product, dispatch, canEdit, showModal, setShowModal, users}) => {
    const curves = product.curves
    const [file, setFile] = useState(null)

    const downloadCurve = async curve => {
        const res = await Call('/commodityProducts/' + product.id + '/curves/' + curve.id)

        const fileName = [
            product.tier1 + ' ' + product.tier2 + '(' + product.productType.code + ')',
            formatDate(curve.asOf),
            CPPriceTypeEnums[curve.priceType]
        ].join('_') + '.csv'

        downloadCSVFromJson(fileName, res.data.prices.map(price => ({
            "Product": product.tier1 + ' ' + product.tier2 + '(' + product.productType.code + ')',
            "As Of": formatDate(curve.asOf),
            "Price Type": CPPriceTypeEnums[curve.priceType],
            "Date": price.date,
            "Price": price.price
        })))
    }

    const handleUpload = () => {
        const formData = new FormData();
        formData.append('file', file);
        
        dispatch(CommodityProductActions.Products.createProductCurve({productId: product.id, formData}));

        setFile(null);
    }
    
    return <div className='commodityCurveCard fullWidth'>
        <div className='cccCurves'>
            <Table 
                columns={[
                    {key: 'priceTypeDesc', title: 'Price Type'},
                    {key: 'asOf', title: 'As Of'},
                    {key: 'createdAt', title: 'Uploaded At'},
                    {key: 'createdBy', title: 'Uploaded By'},
                    {key: 'download', title: 'Download'}
                ]}
                searchBox={false}
                data={(curves || []).map(curve => ({ ...curve, asOf: new Date(curve.asOf), createdAt: new Date(curve.createdAt) }))
                .sort((a, b) => a.asOf < b.asOf ? 1 : -1)  // Descending sort
                .map(curve => {
                    let creator = users.filter(u => u.id === curve.createdBy)[0] || {}
                    curve.createdBy = creator['firstName'] + ' ' + creator['lastName']
                    curve.priceTypeDesc = CPPriceTypeEnums[curve.priceType]
                    curve.download = <span 
                        style={{ fontSize: '22px' }} 
                        onClick={async () => await downloadCurve(curve)}
                        >
                            {Icons.DOWNLOAD}
                        </span>

                    return curve
                })}
            />
        </div>
        <Modal 
            show={showModal}
            cancel={() => setShowModal(false)}
            actions={[
                {children: 'Cancel', buttonType: 'secondaryButton', onClick: () => setShowModal(false)},
                {children: 'Upload', onClick: handleUpload}
            ]}
            title={"Upload Curve"}
        >
            <FileUpload label='' onChange={({target: { files }}) => setFile(files[0])}/>
        </Modal>
    </div>
}

export default Curves
