import { put, takeEvery, select } from "redux-saga/effects";
import Call, { APIException } from "../../../lib/api/fetch";
import { CommodityProductActions } from '../../actions'
import execute from "../execute";


export function* executeGetDataSources(){
    yield execute({
        operation: async () => await Call("/commodityProducts/dataSources", 'GET'),
        save: function* ({ data }){
            yield put(CommodityProductActions.DataSources.saveDataSources(data))
        } 
    })
}

export function* executeCreateDataSource({ payload}){
    const { code, description } = payload;

    yield execute({
        operation: async () => await Call("/commodityProducts/dataSources", 'POST', {code, description}),
        save: function* ({ data }) {
            const dataSources = yield select(state => state.commodityProducts.dataSources) || [];
            yield put(CommodityProductActions.DataSources.saveDataSources([...dataSources, ...data]));
        }
    })
}

export function* executeUpdateDataSource({ payload }){
    const { id, description } = payload;

    yield execute({
        operation: async () => await Call("/commodityProducts/dataSources/" + id, 'PUT', { description }),
        save: function* ({ data }) {
            const dataSources = yield select(state => state.commodityProducts.dataSources) || []
            yield put(CommodityProductActions.DataSources.saveDataSources(dataSources.map(ds => ds.id === id ? data : ds)))           
        }
    })
}

export function* executeDeleteDataSource({ payload }){
    yield execute({
        operation: async () => await Call("/commodityProducts/dataSources/" + payload, 'DELETE'),
        save: function* () {
            const dataSources = yield select(state => state.commodityProducts.dataSources);
            yield put(CommodityProductActions.DataSources.saveDataSources(dataSources.filter(ds => ds.id !== payload)));
        },
        onError: err => {
            if (err instanceof APIException) console.error(err.res.data.message)
            else throw err
        }
    })
}

export default function* DataSourcesWatcher() {
	yield takeEvery(CommodityProductActions.DataSources.types.GET_DATA_SOURCES, executeGetDataSources);
    yield takeEvery(CommodityProductActions.DataSources.types.CREATE_DATA_SOURCES, executeCreateDataSource);
    yield takeEvery(CommodityProductActions.DataSources.types.UPDATE_DATA_SOURCES, executeUpdateDataSource);
    yield takeEvery(CommodityProductActions.DataSources.types.DELETE_DATA_SOURCES, executeDeleteDataSource);
}