import './index.scss';


const DatetimePicker = ({ value="", fullWidth=true, onChange=(e) => true, label=null, id, className='', type='datetime-local'}) => {
    const classNames = ['dtPickerContainer', 'flex-column']

    if (fullWidth) classNames.push('fullWidth');
    
    return <div className={classNames.join(' ')}>
        {label && <label className={"label"} htmlFor={id}>{label}</label>}
        <input id={id} className={['dtPCInput', className].join(' ')} type={type} value={value} onChange={onChange}/>
    </div>
}

export default DatetimePicker;
