import { put, takeEvery, select } from "redux-saga/effects";
import { AppActions, AdminActions } from '../actions'
import Call from "../../lib/api/fetch";
import execute from './execute';


function* executeGetClaims() { 
	yield execute({
		operation: async () => await Call("/admin/claims", "GET"),
		save: function* ({ data }) {
			yield put(AdminActions.Roles.saveClaims(data))
		}
	})
}

function* executeGetRoles() {
	yield execute({
		operation: async () =>  await Call("/admin/roles", "GET"),
		save: function* ({ data }) {
			yield put(AdminActions.Roles.saveRoles(data))
		}
	})
}

function* executeCreateRole({ payload }) {
	const {name, description, claims } = payload;

	yield execute({
		operation: async () => await Call("/admin/roles", "POST", {name, description, claims}),
		save: function* ({ data }) {
			let roles = yield select(state => state.admin.roles);
			yield put(AdminActions.Roles.saveRoles([...roles, ...data]))
		}
	})
}

function* executeUpdateRole({ payload }) {
	const {id, body} = payload;
	const { description, claims } = body;

	yield execute({
		operation: async () => await Call("/admin/roles/" + id, 'PUT', { description, claims: claims.map(c => c.id) }),
		save: function* ({ data }) {
			let roles  = yield select(state => state.admin.roles);
			yield put(AdminActions.Roles.saveRoles(roles.map(role => role.id === id 
				? { ...role, description: data.description, claims: data.claims }
				: role
			)))
		}
	})
}

function* executeDeleteRole({ payload }) {
	yield execute({
		operation: async () => await Call("/admin/roles/" + payload, 'DELETE'),
		save: function* () 	{
			// adjust state roles
			const roles = yield select(state => state.admin.roles);

			yield put(AdminActions.Roles.saveRoles(roles.filter(role => role.id !== payload)))

			// Adjust state users
			const users = yield select(state => state.admin.users);

			// Condition is important, else it updates the state with empty array for users, 
			// which is not null, we need it to be null if it was previously null
			if (!!users) yield put(AdminActions.Users.saveUsers((users).map(user => ({
				...user,
				roles: user.roles.filter(role => role.id !== payload)
			}))))

			// adjust me obj in state
			const me = yield select(state => state.app.me);

			yield put(AppActions.saveMe({ ...me, roles: me.roles.filter(role => role.id !== payload)}))
		}
	})
}

function* executeGetUsers() {
	yield execute({
		operation: async () => await Call("/admin/users", "GET"),
		save: function* ({ data }) { yield put(AdminActions.Users.saveUsers(data)) }
	})
}

function* executeUpdateUser(action) {
	const { id, firstName, lastName, role, roles, expiredAt } = action.payload

	const body = { firstName, lastName, role, expiredAt }

	if (roles != null) body['roles'] = roles.map(r => r.id)

	yield execute({
		operation: async () => await Call("/admin/users/" + id, "PUT", body),
		save: function* ({data}){
			// Adjust state users
			const users = yield select(state => state.admin.users);

			if (!!users) 
				yield put(AdminActions.Users.saveUsers(users.map(user =>  user.id === id ? { ...user, ...data } : user)));

			// Adjust me obj in state
			const me = yield(select(state => state.app.me))

			if (me.id === id) yield put(AppActions.saveMe({
				...me,
				firstName: data.firstName,
				lastName: data.lastName,
				role: data.role,
				roles: data.roles
			}))
		}
	})
}

function* executeCreateUser(action) {
	const { firstName, lastName, role, roles, email } = action.payload

	const body = {
		roles: (roles || []).map(r => r.id),
		firstName,
		lastName,
		role,
		email
	}

	yield execute({
		operation: async () => await Call("/admin/users", "POST", body),
		save: function* ({ data }) {
			const users = yield select(state => state.admin.users);

			yield put(AdminActions.Users.saveUsers([...users, ...data]));
		}
	})
}

function* executeGetMe() { 
	yield execute({
		operation: async () => await Call("/admin/me", "GET"), 
		save: res => put(AppActions.saveMe(res.data))
	})
}

export default function* AdminWatcher() {
	// Roles
	yield takeEvery(AdminActions.Roles.types.GET_ROLES, executeGetRoles);
	yield takeEvery(AdminActions.Roles.types.UPDATE_ROLE, executeUpdateRole);
	yield takeEvery(AdminActions.Roles.types.CREATE_ROLE, executeCreateRole);
	yield takeEvery(AdminActions.Roles.types.DELETE_ROLE, executeDeleteRole);

	// Claims
	yield takeEvery(AdminActions.Roles.types.GET_CLAIMS, executeGetClaims);

	// Users
	yield takeEvery(AdminActions.Users.types.GET_USERS, executeGetUsers);
	yield takeEvery(AdminActions.Users.types.UPDATE_USER, executeUpdateUser);
	yield takeEvery(AdminActions.Users.types.CREATE_USER, executeCreateUser);

    // Me
    yield takeEvery(AppActions.types.GET_ME, executeGetMe);
}
