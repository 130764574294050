import { HiPencil, HiTrash } from 'react-icons/hi'
import { GoClockFill } from "react-icons/go";
import { MdDownloadForOffline, MdCancel } from "react-icons/md";
import { FaCheck } from "react-icons/fa";


export const Icons = {
	EDIT: <HiPencil />,
	DELETE: <HiTrash />,
	CLOCK: <GoClockFill />,
	DOWNLOAD: <MdDownloadForOffline />,
	CANCEL: <MdCancel />,
	CHECK: <FaCheck />
}

export const temperatureUnits = {
	Fahrenheit: 10,
	Celsius: 20,
	Kelvin: 30
}

export const temperatureUnitsEnums = {
	10: 'Fahrenheit',
	20: 'Celsius',
	30: 'Kelvin'
}

export const weatherReadingTypes = {
	Historical: 1,
    Forecast: 2,
    Historical_10Yr_Avg: 10,
    Historical_30Yr_Avg: 11
}

export const weatherReadingTypeEnums = {
    1: "Historical",
    2: "Forecast",
    10: "Historical (10Yr Avg)",
    11: "Historical (30Yr Avg)"
}

export const intervalTypes = {
    ONE_MINUTE: 1, 
    FIVE_MINUTES: 3,
    FIFTEEN_MINUTES: 5,
    THIRTY_MINUTES: 7,
    HOURLY: 10,
    DAILY: 20,
    WEEKLY: 30, 
    MONTHLY: 40,
    YEARLY: 50
}

export const intervalTypeEnums = {
	1: "Minute", 
    3: "5 minutes",
    5: "15 minutes",
    7: "30 minutes",
    10: "Hourly",
    20: "Daily",
    30: "Weekly", 
    40: "Monthly",
    50: "Yearly"
}

export const CPCommodities = {
	NaturalGas: 10,
	Power: 20,
	Capacity: 30,
	Environment: 40,
	Weather: 50
}

export const CPCommoditiesEnums = {
	10: 'Natural Gas',
	20: 'Power',
	30: 'Capacity',
	40: 'Environment',
	50: 'Weather'
}

export const CPSettlements = {
	Financial: 1,
	Physical: 2
}

export const CPSettlementsEnums = {
	1: "Financial",
	2: "Physical"
}

export const CPPriceTypeEnums = {
	0: "Current",
	1: "Open",
	2: "Close",
	5: "Settle"
}

export const CPPriceTypes = {
	Current: 0,
	Open: 1,
	Close: 2,
	Settle: 5
}

export const SettingsPrivileges = {	
	VIEW_ROLES: 'ViewRoles',
	CREATE_EDIT_ROLES: 'CreateEditRoles',
	VIEW_USERS: 'ViewUsers',
	CREATE_EDIT_USERS: 'CreateEditUsers',
}

export const MarketDataPrivileges = {
	VIEW_WEATHER_SOURCES: "ViewWeatherSources",
	CREATE_EDIT_WEATHER_SOURCES: "CreateEditWeatherSources",
	VIEW_WEATHER_STATIONS: "ViewWeatherStations",
	CREATE_EDIT_WEATHER_STATIONS: "CreateEditWeatherStations",

	// Commodity products
	VIEW_COMMODITY_PRODUCTS: "ViewCommodityProducts",
	CREATE_EDIT_COMMODITY_PRODUCTS: "CreateEditCommodityProducts",
	VIEW_COMMODITY_PRODUCT_LOOKUPS: "ViewCommodityProductLookups",
	CREATE_EDIT_COMMODITY_PRODUCT_LOOKUPS: "CreateEditCommodityProductLookups",

	// Utilities:
	VIEW_UTILITIES: 'ViewUtilities',
	CREATE_EDIT_UTILITIES: "CreateEditUtilities"
}

export const States = {
    AK: 1,
    AL: 2,
    AR: 3,
    AZ: 4,
    CA: 5,
    CO: 6,
    CT: 7,
    DE: 8,
    FL: 9,
    GA: 10,
    HI: 11,
    IA: 12,
    ID: 13,
    IL: 14,
    IN: 15,
    KS: 16,
    KY: 17,
    LA: 18,
    MA: 19,
    MD: 20,
    ME: 21,
    MI: 22,
    MN: 23,
    MO: 24,
    MS: 25,
    MT: 26,
    NC: 27,
    ND: 28,
    NE: 29,
    NH: 30,
    NJ: 31,
    NM: 32,
    NV: 33,
    NY: 34,
    OH: 35,
    OK: 36,
    OR: 37,
    PA: 38,
    RI: 39,
    SC: 40,
    SD: 41,
    TN: 42,
    TX: 43,
    UT: 44,
    VA: 45,
    VT: 46,
    WA: 47,
    WI: 48,
    WV: 49,
    WY: 50
}

export const StateEnums = {
    1: "AK",
    2: "AL",
    3: "AR",
    4: "AZ",
    5: "CA",
    6: "CO",
    7: "CT",
    8: "DE",
    9: "FL",
    10: "GA",
    11: "HI",
    12: "IA",
    13: "ID",
    14: "IL",
    15: "IN",
    16: "KS",
    17: "KY",
    18: "LA",
    19: "MA",
    20: "MD",
    21: "ME",
    22: "MI",
    23: "MN",
    24: "MO",
    25: "MS",
    26: "MT",
    27: "NC",
    28: "ND",
    29: "NE",
    30: "NH",
    31: "NJ",
    32: "NM",
    33: "NV",
    34: "NY",
    35: "OH",
    36: "OK",
    37: "OR",
    38: "PA",
    39: "RI",
    40: "SC",
    41: "SD",
    42: "TN",
    43: "TX",
    44: "UT",
    45: "VA",
    46: "VT",
    47: "WA",
    48: "WI",
    49: "WV",
    50: "WY",
}

export const Commodities = {
    Undefined: 0,
    Gas: 1,
    Electric: 2,
    All: 10
}

export const CommodityEnums = {
    1: "Gas",
    2: 'Electric',
    10: 'All'
}

export const RateClassTypes = {
    Residential: 2,
    Residential_With_Elec_Heat: 3,
    Commercial_Small: 4,
    Commercial_Medium: 5,
    Commercial_Large: 6,
    Street_Lighting: 7,
}

export const RateClassTypeEnums = {
    2: "Residential",
    3: "Residential With Electric Heat",
    4: "Commercial Small",
    5: "Commercial Medium",
    6: "Commercial Large",
    7: "Street Lighting"
}

export const MoveToBillPrivileges = {
    GENERATE_MTB_SHEETS: 'GenerateMTBSheets',
    READ_MTB_CONFIG_DATA: 'ReadMTBConfigData',
    WRITE_MTB_CONFIG_DATA: 'WriteMTBConfigData'
}

export const ReportingPrivileges = {
    VIEW_REPORTS: 'ViewReports',
    CREATE_EDIT_REPORTS: 'CreateEditReports',
    VIEW_REPORT_TOPICS: 'ViewReportTopics',
    CREATE_EDIT_REPORT_TOPICS: "CreateEditReportTopics",
    VIEW_REPORT_DISTRIBUTION_LIST: "ViewReportDistributionLists",
    CREATE_EDIT_REPORT_DISTRIBUTION_LIST: "CreateEditReportDistributionLists"
}
