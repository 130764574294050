import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ReportingPrivileges, CommodityEnums, Icons } from "../../lib/constants"
import { ReportingActions} from "../../redux/actions"
import { useNavigate } from "react-router-dom"
import { DeleteModal, Form, Modal, Table } from "../../components"


export const ReportingTopics = {
    1: "Sales and Customer Information",
    2: "Pricing",
    3: "Billing",
    4: "Operations",
    5: "Wholesale Supply",
    6: "Company"
}

const TopicForm = ({topic={}, canEdit=true, handleSubmit, cancelEdit }) => {
    const [values, setValues] = useState({
        ...topic,
        application: topic.application || 0,  // default
        commodity: topic.commodity || 10,  // default
        accessLevels: topic.accessLevels || []
    })

    const [errors, setErrors] = useState({})

    const handleAccessLevelChange = ({ value, index }) => {
        // Create
        if (index === -1) setValues({...values, accessLevels: [...values.accessLevels, value || '']}) 
        // Delete
        else if (value == null) setValues({...values, accessLevels: values.accessLevels.filter((acc, a) => a !== index)})
        // Update
        else setValues({...values, accessLevels: values.accessLevels.map((acc, a) => a === index ? (value || '').trim() : acc )})
    }

    const fields = [
        {
            fieldName: 'application',
            value: values.application || 0,
            label: 'Application',
            type: 'select',
            singleSelectOnly: true,
            options: Object.getOwnPropertyNames(ReportingTopics).map(t => ({id: parseInt(t), name: ReportingTopics[t]})),
            readOnly: !canEdit,
            placeholder: 'Undefined',
            onChange: ({target: {value}}) => setValues({...values, application: value === 'Undefined' ? 0 : parseInt(value)})
        },
        {
            fieldName: 'commodity',
            value: values.commodity || 0,
            label: 'Commodity',
            type: 'select',
            readOnly: !canEdit,
            onChange: ({target: {value}}) => setValues({...values, commodity: value === 'Unknown' ? 0 : parseInt(value)}),
            options: Object.getOwnPropertyNames(CommodityEnums).map(comm => ({ id: parseInt(comm),  name: CommodityEnums[comm] })),
            placeholder: 'Unknown',
            singleSelectOnly: true
        },
        {
            fieldName: 'name',
            value: values.name,
            label: 'Name',
            readOnly: !canEdit,
            onChange: ({target: {value}}) => setValues({...values, name: value})
        },
        {
            fieldName: 'description',
            value: values.description,
            label: 'Description',
            readOnly: !canEdit,
            onChange: ({target: {value}}) => setValues({...values, description: value})
        },
        {
            fieldName: 'accessLevels',
            value: values.accessLevels,
            type: 'array',
            label: 'Access Levels',
            readOnly: !canEdit,
            onChange: accLvl => handleAccessLevelChange(accLvl)
        }
    ]

    const onSubmit = e => {
        e.preventDefault();
        handleSubmit({...values, delete: null})
        cancelEdit(e)
    }

    const formActions = [
        { buttonType: 'secondaryButton', text: 'Cancel', onClick: cancelEdit},
        { text: values.id ? 'Update': 'Create', type: 'submit'}
    ]

    return <Form fields={fields} errors={errors} onSubmit={onSubmit} actions={formActions} canEdit={canEdit} />
}

export default function Module({ canEdit, setCanEdit, showModal, cancelModal }){
    const topics = useSelector(state => state.reporting.topics)
    const me = useSelector(state => state.app.me)

    const [updateTopic, setUpdateTopic] = useState(null)
    const [deleteTopic, setDeleteTopic] = useState(null)

    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {
        if(!!me) {
            let authorized = me.claims.filter(c => c.name === ReportingPrivileges.VIEW_REPORT_TOPICS).length > 0

            if (!authorized) navigate('/')

            else{
                setCanEdit(me.claims.filter(c => c.name === ReportingPrivileges.CREATE_EDIT_REPORT_TOPICS).length > 0)

                if (!topics) dispatch(ReportingActions.getReportingTopics())
            }
        }
    })

    const getColumnStyle = width => ({"textWrap": "wrap", "width": width });

    const columns = [
        {key: 'appName', title: 'Application', styles: getColumnStyle('15%')},
        {key: 'name', title: 'Name', styles: getColumnStyle('20%')},
        {key: 'description', title: 'Description', styles: getColumnStyle()},
        {key: 'comm', title: 'Commodity', styles: getColumnStyle('10%')},
        {key: 'accLvl', title: 'Access Levels', styles: getColumnStyle('15%')},
        {key: 'delete', title: '', styles: getColumnStyle('25px')}
    ]

    return <>
        <Table searchBox={false} columns={columns} data={(topics || []).map(topic => ({
                ...topic,
                appName: ReportingTopics[topic.application],
                comm: CommodityEnums[topic.commodity],
                accLvl: topic.accessLevels && topic.accessLevels.length > 0 ? topic.accessLevels.join(', ') : "",
                delete: <div style={{fontSize: '20px'}} onClick={(e) => {
                    e.stopPropagation()
                    setDeleteTopic(topic)
                }}>{Icons.DELETE}</div>
            }))}
            rowOnClick={({e, row}) => setUpdateTopic(row)}
        />
        <Modal title='Update Topic' show={!!updateTopic} cancel={() => setUpdateTopic(null)}>
            <TopicForm 
                topic={updateTopic}
                canEdit={canEdit}
                cancelEdit={() => setUpdateTopic(null)}
                handleSubmit={values => dispatch(ReportingActions.updateReportingTopics(values))}
            />
        </Modal>
        <DeleteModal 
            deleteMode={!!deleteTopic}
            setDeleteMode={() => setDeleteTopic(null)}
            onDelete={() => dispatch(ReportingActions.deleteReportingTopic(deleteTopic.id))}
            warningMessage={deleteTopic && `Are you sure you want to delete -  ${ReportingTopics[deleteTopic.application]}: ${deleteTopic.name}?`}
        />
        <Modal title='Add Topic' show={showModal} cancel={cancelModal}>
            <TopicForm 
                handleSubmit={values => dispatch(ReportingActions.createReportingTopics(values))} 
                cancelEdit={cancelModal}
            />
        </Modal>
    </>
}
