export const types = {
    // Distributors
    GET_REPORTING_TOPICS: "GET_REPORTING_TOPICS",
    SAVE_REPORTING_TOPICS: "SAVE_REPORTING_TOPICS",

    CREATE_REPORTING_TOPIC: "CREATE_REPORTING_TOPIC",
    UPDATE_REPORTING_TOPIC: "UPDATE_REPORTING_TOPIC",
    DELETE_REPORTING_TOPIC: "DELETE_REPORTING_TOPIC",

    // DL
    GET_REPORTING_DISTRIBUTION_LIST: "GET_REPORTING_DISTRIBUTION_LIST",
    SAVE_REPORTING_DISTRIBUTION_LIST: "SAVE_REPORTING_DISTRIBUTION_LIST",
    CREATE_DISTRIBUTION_LIST: "CREATE_DISTRIBUTION_LIST",
    UPDATE_DISTRIBUTION_LIST: "UPDATE_DISTRIBUTION_LIST",
    DELETE_DISTRIBUTION_LIST: "DELETE_DISTRIBUTION_LIST",

    // Reports
    GET_REPORTS: "GET_REPORTS",
    SAVE_REPORTS: "SAVE_REPORTS",
    CREATE_REPORT: "CREATE_REPORT",
    UPDATE_REPORT: "UPDATE_REPORT",
    DELETE_REPORT: "DELETE_REPORT"
}

// Topics
export const getReportingTopics = () => ({type: types.GET_REPORTING_TOPICS});

export const saveReportingTopics = payload => ({type: types.SAVE_REPORTING_TOPICS, payload});

export const createReportingTopics = payload => ({type: types.CREATE_REPORTING_TOPIC, payload});

export const updateReportingTopics = payload => ({type: types.UPDATE_REPORTING_TOPIC, payload});

export const deleteReportingTopic = payload => ({type: types.DELETE_REPORTING_TOPIC, payload});

// Distribution List
export const getDistributionList = () => ({type: types.GET_REPORTING_DISTRIBUTION_LIST});

export const saveDistributionList = payload => ({type: types.SAVE_REPORTING_DISTRIBUTION_LIST, payload});

export const createDistributionList = payload => ({type: types.CREATE_DISTRIBUTION_LIST, payload});

export const updateDistributionList = payload => ({type: types.UPDATE_DISTRIBUTION_LIST, payload});

export const deleteDistributionList = payload => ({type: types.DELETE_DISTRIBUTION_LIST, payload});

// Reports
export const getReports = () => ({type: types.GET_REPORTS});

export const saveReports = payload => ({type: types.SAVE_REPORTS, payload});

export const createReport = payload => ({type: types.CREATE_REPORT, payload});

export const updateReport = payload => ({type: types.UPDATE_REPORT, payload});

export const deleteReport = payload => ({type: types.DELETE_REPORT, payload});
