import { Page } from "../../components";
import TopicsModule from './topics';
import ReportsModule from "./reports";
import DistributionListModule from './distributionList'


const sideBarRoutes = [
	{title: 'Reports', route: '/reporting/reports'},
	{title: 'Topics', route: '/reporting/topics'},
    {title: 'Distribution List', route: '/reporting/distributionList'}
]


export function Reports() {
	return <Page 
		pageName={'Reports'}
        Component={ReportsModule}
		sideBarRoutes={sideBarRoutes}
		actions={[{
			showsModal: true,
			disableByCanEdit: true,
			children: "Upload Report"
		}]}
		includeAddButton={false}
		widescreen
	/>
}


export function Topics() {
	return <Page 
		pageName={'Topics'}
        Component={TopicsModule}
		sideBarRoutes={sideBarRoutes}
		widescreen
	/>
}


export function DistributionList() {
	return <Page 
		pageName={'Distributors'}
        Component={DistributionListModule}
		sideBarRoutes={sideBarRoutes}
		widescreen
	/>
}
