import { ReportingActions  } from '../actions'

const { SAVE_REPORTING_DISTRIBUTION_LIST, SAVE_REPORTING_TOPICS, SAVE_REPORTS } = ReportingActions.types

const initialState = {
    reports: null,
    topics: null,
    distributionList: null
}

export default function reducer(state = initialState, action) {
    const { type, payload } = action;

	switch (type) {
		case SAVE_REPORTING_TOPICS:
			return {
                ...state,
                topics: payload
            }
        
        case SAVE_REPORTING_DISTRIBUTION_LIST:
            return {
                ...state,
                distributionList: payload
            }

        case SAVE_REPORTS:
            return {
                ...state,
                reports: payload
            }

		default:
			return state
	}
}
