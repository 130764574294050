import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useEffect } from "react"
import { CommodityProductActions } from "../../../redux/actions"
import { CPCommoditiesEnums, MarketDataPrivileges } from "../../../lib/constants"
import { Modal, Table } from '../../../components'
import { getSymbol, ProductForm } from './product_id'


export default function Module ({ canEdit, setCanEdit, showModal, cancelModal, filters, setFilters}){
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const me = useSelector(state => state.app.me)
    const products = useSelector(state => state.commodityProducts.products)
    const dataSources = useSelector(state => state.commodityProducts.dataSources)
    const productTypes = useSelector(state => state.commodityProducts.productTypes);

    useEffect(() => {
        if(!!me){
            let authorized = me.claims.filter(c => c.name === MarketDataPrivileges.VIEW_COMMODITY_PRODUCTS).length > 0

            if (!authorized) navigate('/')

            else{
                setCanEdit(me.claims.filter(c => c.name === MarketDataPrivileges.CREATE_EDIT_COMMODITY_PRODUCTS).length > 0)
    
                if (!products) dispatch(CommodityProductActions.Products.getProducts())
                
                else if (!dataSources) dispatch(CommodityProductActions.DataSources.getDataSources());

                else if (!productTypes) dispatch(CommodityProductActions.ProductTypes.getProductTypes());
            }
        }
    })

    return <>
        <Table 
            columns={[
                {title: 'Name', key: 'name'},
                {title: 'Product Type', key: 'productTypeName'},
                {title: 'Symbol', key: 'displaySymbol'},
                {title: 'Commodity', key: 'commodityCode'},
                {title: 'Settlement', key: 'settlement'}
            ]}
            searchBox={false}
            data={
                // Transform product
                (products || []).map(product => ({
                    ...product,
                    commodityCode: CPCommoditiesEnums[product.productType.commodity],
                    settlement: product.productType.settlement === 2 ? 'Physical' : 'Financial',
                    name: product['tier1'] + ' ' + product['tier2'],
                    productTypeName: product.productType['code'],
                    displaySymbol: getSymbol({product, dataSources})
                }))
                // Commodity filter
                .filter(product => filters.commodity ? product.productType.commodity === filters.commodity : true)
                // Product type filter
                .filter(product => filters.productTypeId ? product.productType.id === filters.productTypeId : true)
                // Search text filter
                .filter(product => filters.searchText ? filters.searchText.split(' ').filter(st => (
                    (product.tier1 || '').toLowerCase().includes(st.toLowerCase()) || 
                    (product.tier2 || '').toLowerCase().includes(st.toLowerCase()) || 
                    (product.tier3 || '').toLowerCase().includes(st.toLowerCase()) || 
                    (product.tier4 || '').toLowerCase().includes(st.toLowerCase()) ||
                    product.codes.filter(code => (code.symbol || '').toLowerCase().includes(st.toLowerCase())).length > 0
                )).length > 0 : true)
            }
            rowOnClick={({e, row}) => navigate('/market/commodityProducts/' + row.id)}
        />
        <Modal 
            title='Add Commodity Product'
            show={showModal}
            cancel={cancelModal}
        >
            <ProductForm 
                canEdit={canEdit} 
                cancelEdit={cancelModal} 
                productTypes={productTypes}
                handleSubmit={product => dispatch(CommodityProductActions.Products.createProduct({
                    product,
                    navigate
                }))} 
            />
        </Modal>
    </>
};
