import { put, takeEvery, select } from "redux-saga/effects";
import Call from "../../../lib/api/fetch";
import { AppActions, CommodityProductActions } from '../../actions'
import execute from '../execute';
import axios from 'axios';
import AEConfig from '../../../lib/ae-config.json'
import { cookieService } from "../../../lib/helpers";


export function* executeGetProducts(){
    yield execute({
        operation: async () => await Call("/commodityProducts", "GET"),
        save: function* ({data}) {
            yield put(CommodityProductActions.Products.saveProducts(data))  
        } 
    })
}

export function* executeCreateProduct({payload}){
    const { product, navigate } = payload 
    yield execute({
        operation: async () => await Call("/commodityProducts", "POST", product),
        save: function* ({ data }) {
            const product = data[0]

            const products = yield select(state => state.commodityProducts.products) || [];

            yield put(CommodityProductActions.Products.saveProducts([...products, ...data]))

            navigate('/market/commodityProducts/' + product.id)
        }
    })
}

export function* executeUpdateProduct({payload}){
    const { id, tier1, tier2, tier3, tier4, addToMarkingSheet, isActive } = payload

    yield execute({
        operation: async () => await Call("/commodityProducts/" + id, "PUT", { tier1, tier2, tier3, tier4, addToMarkingSheet, isActive }),
        save: function* ({ data }) {
            const products = yield select(state => state.commodityProducts.products)
            
            // Update API only returns table fields as updated, do not override entire productSummary
            yield put(CommodityProductActions.Products.saveProducts(products.map(
                p => p.id === payload.id 
                ? {
                    ...p,
                    tier1: data.tier1, 
                    tier2: data.tier2, 
                    tier3: data.tier3, 
                    tier4: data.tier4,
                    addToMarkingSheet: data.addToMarkingSheet, 
                    isActive: data.isActive,
                }
                : p
            )))
        }
    })
}

export function* executeDeleteProduct({payload}){
    yield execute({
        operation: async () => await Call("/commodityProducts/" + payload, "DELETE"),
        save: function* ({ data }) {
            const products = yield select(state => state.commodityProducts.products)

            yield put(CommodityProductActions.Products.saveProducts(products.filter(p => p.id !== data.id)))
        }
    })
}

export function* executeUpdateProductCodes({payload}){
    const { productId, codes } = payload

    yield execute({
        operation: async () => await Call('/commodityProducts/' + productId + '/codes', "PUT", codes),
        save: function* ({ data }){
            const products = yield select(state => state.commodityProducts.products);

            yield put(CommodityProductActions.Products.saveProducts(products.map(product => product.id === productId 
                ? { ...product, codes: data }
                : product
            )))           
        }
    })
}

export function* executeGetProductUpdateRules({payload}){
    yield execute({
        operation: async () => await Call('/commodityProducts/' + payload + '/updateRules', 'GET'),
        save: function* ({ data }) {
            const products = yield select(state => state.commodityProducts.products) 

            yield put(
                CommodityProductActions.Products.saveProducts(products.map(product => product.id === payload 
                    ? { ...product, updateRules: data }
                    : product
                )))
        }
    })
}

export function* executeCreateProductUpdateRules({ payload }){
    const {updateRule, productId} = payload

    yield execute({
        operation: async () => await Call('/commodityProducts/' + productId + '/updateRules/', "POST", updateRule),
        save: function* ({ data }){
            let products = yield select(state => state.commodityProducts.products)

            yield put(CommodityProductActions.Products.saveProducts(products.map(
                product => product.id === productId 
                    ? {
                        ...product, 
                        updateRules: [...product.updateRules, ...data]
                    }
                    : product
            )))
        }
    })        
}

export function* executeUpdateProductUpdateRules({ payload }){
    const {updateRule, productId} = payload

    yield execute({
        operation: async () => await Call('/commodityProducts/' + productId + '/updateRules/' + updateRule.id, "PUT", updateRule),
        save: function* ({ data }){
            let products = yield select(state => state.commodityProducts.products)

            yield put(CommodityProductActions.Products.saveProducts(products.map(
                product => product.id === productId 
                    ? {
                        ...product, 
                        updateRules: product.updateRules.map(rule => rule.id === updateRule.id 
                            ? data
                            : rule
                        )
                    }
                    : product
            )))
        }
    })        
}

export function* executeDeleteProductUpdateRules({ payload }){
    const {ruleId, productId} = payload

    yield execute({
        operation: async () => await Call('/commodityProducts/' + productId + '/updateRules/' + ruleId, "DELETE"),
        save: function* ({ data }){
            let products = yield select(state => state.commodityProducts.products)

            yield put(CommodityProductActions.Products.saveProducts(products.map(
                product => product.id === productId 
                    ? {
                        ...product, 
                        updateRules: product.updateRules.filter(rule => rule.id !== ruleId)
                    }
                    : product
            )))
        }
    })        
}

export function* executeGetProductCurves({ payload }){
    yield execute({
        operation: async () => await Call('/commodityProducts/' + payload + '/curves', 'GET'),
        save: function* ({ data }) {
            const products = yield select(state => state.commodityProducts.products)
            yield put(CommodityProductActions.Products.saveProducts(products.map(p => p.id === payload ? {...p, curves: data} : p)))
        }
    })
}

export function* executeUploadProductCurve({ payload }){
    const cookie = cookieService.get("ae_app_user_jwt");

    const { productId, formData } = payload;

    yield execute({
        operation: async () => await axios.post(
            AEConfig.API_URL + '/commodityProducts/' + productId + '/curves?fileFormat=20', 
            formData, 
            {
                'headers': {
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': 'Bearer ' + cookie
                }
            }
        ),
        save: function* (res) {
            const products = yield select(state => state.commodityProducts.products)

            const product = products.filter(p => p.id === productId);

            yield put(CommodityProductActions.Products.saveProducts(
                products.map(p => p.id === productId
                    // Add new curve to the list of curves for that product, if found
                    ? { ...p, curves: [...(p.curves || []), res.data.filter(c => c.curveId === productId)]}
                    : p
                )
            ))

            yield put(AppActions.addNotification({message: 'Curve uploaded for ' + product.tier1 + ' ' + product.tier2}))
        }
    })
}

export default function* ProductsWatcher() {
	yield takeEvery(CommodityProductActions.Products.types.GET_PRODUCTS, executeGetProducts);
    yield takeEvery(CommodityProductActions.Products.types.CREATE_PRODUCT, executeCreateProduct);
    yield takeEvery(CommodityProductActions.Products.types.UPDATE_PRODUCT, executeUpdateProduct);
    yield takeEvery(CommodityProductActions.Products.types.DELETE_PRODUCT, executeDeleteProduct);
    yield takeEvery(CommodityProductActions.Products.types.UPDATE_PRODUCT_CODES, executeUpdateProductCodes);
    yield takeEvery(CommodityProductActions.Products.types.GET_PRODUCT_UPDATE_RULES, executeGetProductUpdateRules);
    yield takeEvery(CommodityProductActions.Products.types.CREATE_PRODUCT_UPDATE_RULE, executeCreateProductUpdateRules);
    yield takeEvery(CommodityProductActions.Products.types.UPDATE_PRODUCT_UPDATE_RULE, executeUpdateProductUpdateRules);
    yield takeEvery(CommodityProductActions.Products.types.DELETE_PRODUCT_UPDATE_RULE, executeDeleteProductUpdateRules);
    yield takeEvery(CommodityProductActions.Products.types.GET_PRODUCT_CURVES, executeGetProductCurves);
    yield takeEvery(CommodityProductActions.Products.types.CREATE_PRODUCT_CURVES, executeUploadProductCurve);
} 