import cookieService from "../helpers/cookieService";
import AEConfig from '../ae-config.json'

export class APIException extends Error{
	constructor({message, method='GET', url}){
		super(method + ' API call failed at ' + url)
		this.res = message
	}
}

// Function to make API call
export default async function Call(url, method = "GET", body = {}, headers = {}, params = {}) {
	let res;

	const cookie = cookieService.get("ae_app_user_jwt");

	const options = {
		method: method.toLowerCase(),
		headers: {
			"Content-Type": "application/json;charset=UTF-8",
			"Access-Control-Allow-Origin": "*",
			Authorization: `Bearer ${cookie}`,
			...headers,
		}
	}

	if (method !== "GET") options.body = JSON.stringify(body)

	let apiUrl = AEConfig.API_URL + url

	if (Object.keys(params).length > 0){
		apiUrl += '?' + new URLSearchParams(params)
	}

	res = await fetch(apiUrl, {...options });

	const data = await res.json();

	if (res.status >= 400) throw new APIException({message: data, method, url});

	return data;
}
