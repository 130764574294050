import './selectBox.scss'
import InputBox from '../inputBox'
import { MdCancel } from 'react-icons/md'

const generateSelectBoxOptions = ({ options, placeholder='Select an option', includePlaceholder=true }) => {
    let res = options.map((opt, o) => <option key={opt.id} value={opt.id}>{!opt.name ? opt.code : opt.name}</option>)

    if (!includePlaceholder) return res
    
    return <>
        <option value={null}>{placeholder}</option>
        {res}
    </>
}


function SingleSelectBox({
    options=[{name: 'Select an option', value: null}],
    label=null,
    onChange,
    onSelect,
    id,
    placeholder='Select an option',
    selected,
    disabled,
    errorMessage,
    includePlaceholder=true,
    ...props
}){
    let className = ['selectBoxContainer', 'fullWidth', 'flex-column'].join(' ')

    if (props.className) className += ' ' + props.className

    return <div className={className} {...props}>
        {label && <label htmlFor={id} className={'label'}>{label}</label>}
        <select onChange={onChange} onSelect={onSelect} value={selected[0] ? selected[0].id || '' : ''} disabled={disabled}>
            {generateSelectBoxOptions({ options, placeholder, includePlaceholder })}
        </select>
        {errorMessage && <p className={"errorMessage"}>{errorMessage}</p>}
    </div>
}


/**
 * Options and selected should be of format [{ id: '', name: ''}]
 */
export default function SelectBox(
    {
        singleSelectOnly=false,
        options=[{name: 'Select an option', id: null}],
        selected=[],
        label=null,
        disabled,
        onDelete,
        onChange,
        onSelect,
        placeholder,
        errorMessage,
        includePlaceholder=true,
        ...props
    }
){
    if (singleSelectOnly) return <SingleSelectBox         
        options={options}
        selected={selected}
        label={label}
        disabled={disabled}
        onChange={onChange}
        onSelect={onSelect}
        placeholder={placeholder}
        errorMessage={errorMessage}
        includePlaceholder={includePlaceholder}
        {...props}
    />

    // Allow placeholder below so all options can be selected
    let available_options = [{id: '0', name: placeholder}].concat(
        options.filter(
            (opt) => {
                for (let select of selected){
                    if (select.id === opt.id) return false
                }
                return true
            }
        )
    )

    let className = ['selectBoxContainer'].join(' ')

    if (props.className) className += ' ' + props.className

    return <div className={className} {...props}>
        {label && <div className={'label'}>{label}</div>}
        {selected.map((val, v) => (
            <div key={v} className={'selectedOption flex alignCenter'}>
                <InputBox key={v} value={val.name} readOnly fullWidth/>
                {/*Function below cannot work with event object, don't try chaging until method found*/}
                {!disabled && <span value={val.id} onClick={() => onDelete(val.id)}><MdCancel /></span>}
            </div>
        ))}
        {!disabled && available_options.length > 1 && <select className='fullWidth' onChange={onChange} onSelect={onSelect} value='0'>
            {available_options.map((opt, o) => <option key={o} value={opt.id}>{opt.name}</option>)}
        </select>
        }
        {errorMessage && <p className={"errorMessage"}>{errorMessage}</p>}
    </div>
}

