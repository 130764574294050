import { useSelector } from 'react-redux';
import { Layout } from '../../components';
import { MarketDataPrivileges } from '../../lib/constants';
import { useNavigate } from 'react-router-dom';
import checkAccess from '../../lib/helpers/checkAccess';

const MarketMenuModule = props => {
	const me = useSelector(state => state.app.me);
	const navigate = useNavigate();

	if (!me) return <></>;

	const menuOptions = [
		// {
		// 	title: 'Weather',
		// 	route: '/market/weather',
		// 	disabled: checkAccess({  
		// 		me,
		// 		accessClaims: [
		// 			MarketDataPrivileges.VIEW_WEATHER_SOURCES,
		// 			MarketDataPrivileges.CREATE_EDIT_WEATHER_SOURCES,
		// 			MarketDataPrivileges.VIEW_WEATHER_STATIONS,
		// 			MarketDataPrivileges.CREATE_EDIT_WEATHER_STATIONS,
		// 		],
		// 	}),
		// },
		{
			title: 'Commodity Products',
			route: '/market/commodityProducts',
			disabled: checkAccess({
				me,
				accessClaims: [
					MarketDataPrivileges.VIEW_COMMODITY_PRODUCTS,
					MarketDataPrivileges.CREATE_EDIT_COMMODITY_PRODUCTS,
					MarketDataPrivileges.VIEW_COMMODITY_PRODUCT_LOOKUPS,
					MarketDataPrivileges.CREATE_EDIT_COMMODITY_PRODUCT_LOOKUPS,
				],
			}),
		},
		// {
		// 	title: 'Utilities',
		// 	route: '/market/utilities',
		// 	disabled: checkAccess({
		// 		me,
		// 		accessClaims: [MarketDataPrivileges.VIEW_UTILITIES, MarketDataPrivileges.CREATE_EDIT_UTILITIES],
		// 	}),
		// },
	];

	return (
		<div className="moduleContainer">
			{menuOptions
				.filter(opt => !opt.disabled)
				.map(({ title, route }, m) => (
					<div key={m} className="marketDataMenuItem cardBorders textAlignCenter" onClick={e => navigate(route)}>
						{title}
					</div>
				))}
		</div>
	);
};

export const MarketMenu = () => {
	return (
		<Layout header="Market Data" centerHeader={true}>
			<MarketMenuModule />
		</Layout>
	);
};
