import { useEffect, useState } from 'react';
import InputBox from '../inputBox';
import './tables.scss';
import { formatDate } from '../../lib/helpers';


const Table = ({
    columns=[], 
    data=[], 
    rowActions=[],
    rowOnClick=null, 
    searchFields=['name'],
    sortOptions=[],
    searchBox=true,
    searchPlaceholder='Search',
    addGrid=false
}) => {
    const [sortKey, setSortKey] = useState(sortOptions[0] && sortOptions[0].key)
    const [sortAsc, setSortAsc] = useState(true)

    const sortRecords = records => {
        return records.sort(
            (a, b) => a[sortKey] < b[sortKey] 
                ? sortAsc ? -1 : 1
                : sortAsc ? 1 : -1
        )
    }

    const rowClassNames = ['tableRow']
    const tableClassNames = ['tableComponent', 'fullWidth']

    if (addGrid) tableClassNames.push('tableGrid')
       
    const [records, setRecords]  = useState(sortRecords(data))
    
    const [search, setSearch] = useState('')

    const handleSearchChange = ({target: { value }}) => {
        setSearch(value.trim())
        if (value.trim() === "") setRecords(sortRecords(data))
        else setRecords(
            sortRecords(
                data.filter(record => searchFields.filter(sf => record[sf].toLowerCase().includes(value.toLowerCase())).length > 0)
            )
        )
    }

    if (!!rowOnClick) rowClassNames.push('cursorPointer')

    const clickOnHeader = ({e, key}) => {
        if (key !== sortKey) {
            setSortKey(key)
            setSortAsc(true)
        }
        else setSortAsc(!sortAsc)
    }

    useEffect(() => {
        setRecords(sortRecords(data))
    }, [data])

    return <div className='tableContainer'>
        <div className='flex fullWidth tableTools'>
            <div className='tableTool'>
                {searchBox && <InputBox placeholder={searchPlaceholder} value={search} onChange={handleSearchChange} />}
            </div>
            {/* <div className='tableTool'>
                {sortOptions.length === 0 
                    ? <></>
                    : <SelectBox
                        singleSelectOnly={true}
                        placeholder={'Sort by'}
                        options={sortOptions.map(opt => ({id: opt.key, name: opt.title}))}
                        value={sortKey}
                        
                    />}
            </div> */}
        </div>
        <table className={tableClassNames.join(" ")}>
            <thead className='tableHeader'>
                <tr className='tableColumnNames'>
                    {columns.map(({ title, key, styles={}}) => {
                        return <th style={styles} onClick={(e) => clickOnHeader({e, key})} key={key}>{title}</th>
                    })}
                </tr>
            </thead>
            <tbody className='tableBody'>
                {records.map((row, r) => <tr className={rowClassNames.join(' ')} key={row.id || r} onClick={e => !rowOnClick ? () => null: rowOnClick({e, row})}>
                    {columns.map(({key, styles={}}) => {
                        return <td key={key} style={styles}>
                            {row[key] instanceof Date ? formatDate(row[key]) : row[key]}
                        </td>
                    })}
                </tr>)}
            </tbody>
        </table>
    </div>
}

export default Table;
