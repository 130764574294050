import ReRoute from '../lib/helpers/reRoute'
import { Reports, Topics, DistributionList } from '../modules/reporting'

const ReportingRoutes = {
    path: 'reporting',
    element: <ReRoute reRoute={'/reporting/reports'} />,
    subRoutes: [
        {
            path: 'reports',
            component: Reports
        },
        {
            path: 'topics',
            component: Topics
        },
        {
            path: 'distributionList',
            component: DistributionList
        }
    ]
}

export default ReportingRoutes;
