import { useNavigate, useLocation } from "react-router-dom";
import { useState } from "react";
import Layout from "./layout";
import SearchBar from './searchBar'


export default function Page({
    pageName, 
    Component,
	componentProps={},
    sideBarRoutes=[],
	prevPage={ to: '/', text: 'Back to Home' },	
	addButtonObj,
	filterFields=[],
	widescreen=false,
	searchBar=[],
	includeAddButton=true,
	actions=[],
	...props
}){
	const navigate = useNavigate()
	const location = useLocation()

	const [canEdit, setCanEdit] = useState(false)
	const [showModal, setShowModal] = useState(false)

	const cancelModal = () => setShowModal(false)

	if (includeAddButton) actions.push({
		onClick: () => setShowModal(true),
		children: 'Add ' + (addButtonObj ? addButtonObj : pageName),
		disabled: !canEdit
	})

	const containerClassNames = ['moduleContainer']

	if (!widescreen) containerClassNames.push("moduleContainerGutter")

	return <Layout 
		header={pageName}
		sideBarProps={{
			navProps: {
				routes: sideBarRoutes.map(sbr => ({
					title: sbr.title,
					onClick: () => sbr.onClick ? sbr.onClick() : navigate(sbr.route),
					isSelected: sbr.selected ? sbr.selected : location.pathname === sbr.route
				}))
			},
			prevPage: widescreen & !prevPage ? null : prevPage
		}}
		actions={
			actions
			// This feature allows adding action buttons but use the page component properties
			.map(act => ({ 
				...act, 
				onClick: act.showsModal ? () => setShowModal(true) : act.onClick,
				disabled: act.disableByCanEdit ? !canEdit : act.disabled
			}))
			.filter(a => !a.disabled)}
		filterFields={filterFields}
	>
		<div className={containerClassNames.join(' ')}>
			{searchBar && searchBar.length > 0 &&<div className="moduleSearchBar">
				<SearchBar searchFields={searchBar} />
			</div>}
			<Component 
				canEdit={canEdit}
				setCanEdit={setCanEdit} 
				showModal={showModal} 
				cancelModal={cancelModal}
				{...componentProps}
			/> 
		</div>
	</Layout>
}
