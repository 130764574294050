import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react"

import { AdminActions, CommodityProductActions } from "../../../../redux/actions"
import { MarketDataPrivileges, CPCommoditiesEnums, CPCommodities } from "../../../../lib/constants"

import { DeleteModal, Form, Page } from '../../../../components'
import DataSourceCodes from './dataSourceCodes'
import UpdateRules from './updateRules'
import Curves from './curves'


export const getSymbol = ({product, dataSources}) => {
    let codes = product['codes']

    codes = codes.map(code => {
        let source = (dataSources || []).filter(ds => ds.id === code.sourceId)[0]
        if (!source) return code
        code.source = source.code
        return code
    })

    let displayCode = codes.filter(c => c.source === 'ICE')[0]

    if (!displayCode) displayCode = codes.filter(c => c.source === 'Nex')[0]

    if (!displayCode) displayCode = codes.filter(c => c.source === 'Platts')[0]

    if (!!displayCode){
        displayCode = "[" + displayCode.source + ":" + displayCode.symbol + ']'
    }

    return displayCode
}


export const tierLabels = {
    [CPCommodities.NaturalGas]: {
        tier1: 'Pipeline',
        tier2: 'Point'
    },
    [CPCommodities.Power]: {
        tier1: 'ISO',
        tier2: 'Zone'
    },
    [CPCommodities.Capacity]: {
        tier1: 'ISO',
        tier2: 'LDA'
    },
    [CPCommodities.Environment]: {
        tier1: 'State',
        tier2: 'REC Type'
    }
}


export const ProductForm = ({ 
    product={},
    canEdit=true, 
    handleSubmit = () => null, 
    cancelEdit = () => null, 
    productTypes=[]
}) => {
    const [values, setValues] = useState(product)
    const [errors, setErrors] = useState({})

    const productType = values.productType

    const pttPlaceHolder = 'Select product type'

    const fields = [
        {
            fieldName: 'productType',
            value: productType ? productType.id : null,
            label: 'Product Type',
            type: 'select',
            readOnly: !!values.id,
            onChange: ({target: {value}}) => {
                if (value === pttPlaceHolder) return
                setValues({...values, productType: productTypes.filter(pt => pt.id === value)[0]})
            },
            options:(
                productTypes.length === 0 && product.productType 
                ?   [{id: product.productType.id, name: CPCommoditiesEnums[product.productType.commodity] + ': ' + product.productType.code }]
                : productTypes.map(pt => ({ id: pt.id, name: CPCommoditiesEnums[pt.commodity] + ': ' + pt.code}))
            ),
            placeholder: pttPlaceHolder,
            singleSelectOnly: true
        },
        {
            fieldName: 'tier1',
            value: values.tier1,
            label: productType && productType.commodity && tierLabels[productType.commodity].tier1,
            readOnly: !canEdit,
            hidden: !productType || !tierLabels[productType.commodity].tier1,
            onChange: ({target: {value}}) => setValues({...values, tier1: value})
        },
        {
            fieldName: 'tier2',
            value: values.tier2,
            label: productType && productType.commodity && tierLabels[productType.commodity].tier2,
            readOnly: !canEdit,
            hidden: !productType || !tierLabels[productType.commodity].tier2,
            onChange: ({target: {value}}) => setValues({...values, tier2: value})
        },
        {
            fieldName: 'tier3',
            value: values.tier3,
            label: 'Tier 3',
            readOnly: !canEdit,
            hidden: !productType || !tierLabels[productType.commodity]['tier3'],
            onChange: ({target: {value}}) => setValues({...values, tier3: value})
        },
        {
            fieldName: 'tier4',
            value: values.tier4,
            label: 'Tier 4',
            readOnly: !canEdit,
            hidden: !productType || !tierLabels[productType.commodity]['tier4'],
            onChange: ({target: {value}}) => setValues({...values, tier4: value})
        },
        {
            fieldName: 'addToMarkingSheet',
            type: 'checkbox',
            readOnly: !canEdit,
            onClick: () => setValues({...values, addToMarkingSheet: !values.addToMarkingSheet}),
            options: [{
                id: 'addToMarkingSheet',
                name: 'Add to Marking Sheet',
                checked: values.addToMarkingSheet
            }]
        }
    ]

    const onSubmit = e => {
        e.preventDefault();
        let errObj = {}

        if (!values.productType) errObj = {...errObj, productType: 'Select a product type'}

        if (Object.keys(errObj).length > 0) {
            setErrors(errObj)
            return
        }

        handleSubmit({...values, productTypeId: values.productType.id})
        
        cancelEdit(e)
    }

    const formActions = [
        {text: values.id ? 'Update' : 'Create', type: 'submit'}
    ]
    
    return <Form fields={fields} errors={errors} onSubmit={onSubmit} actions={formActions} canEdit={canEdit} />
}


export function ProductId(){
    const me = useSelector(state => state.app.me);
    const products = useSelector(state => state.commodityProducts.products)
    const dataSources = useSelector(state => state.commodityProducts.dataSources)
    const users = useSelector(state => state.admin.users)

    const [showModal, setShowModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    
    const [canEdit, setCanEdit] = useState(false)
    const [product, setProduct ] = useState({})

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { productId } = useParams()
    
    useEffect(() => {
        if(!!me){
            let authorized = me.claims.filter(c => c.name === MarketDataPrivileges.VIEW_COMMODITY_PRODUCTS).length > 0

            if (!authorized) navigate('/')

            else{
                setCanEdit(me.claims.filter(c => c.name === MarketDataPrivileges.CREATE_EDIT_COMMODITY_PRODUCTS).length > 0)
    
                if (!dataSources) dispatch(CommodityProductActions.DataSources.getDataSources())
                
                else if (!users) dispatch(AdminActions.Users.getUsers())

                else {
                    if (!products) dispatch(CommodityProductActions.Products.getProducts())

                    else{
                        let product = (products || []).filter(p => p.id === productId)[0]

                        if (!product.curves) dispatch(CommodityProductActions.Products.getProductCurves(productId))

                        else if (!product.updateRules) dispatch(CommodityProductActions.Products.getProductUpdateRules(productId))

                        setProduct(product)
                    }
                }
            }
        }
    }, [me, productId, products, dataSources, users, dispatch, navigate, setProduct])

    let pageName = ''

    if (!!product) {
        pageName = product['tier1'] + ' ' + product['tier2']

        if (product.displaySymbol) pageName += ' ' + product.displaySymbol
    }  

    return <Page 
        pageName={pageName}
        Component={() => <>
            <div className='contentSection'>
                <div className='contentSectionTitle'>Overview</div>
                <ProductForm 
                    product={product} 
                    canEdit={canEdit} 
                    handleSubmit={e => dispatch(CommodityProductActions.Products.updateProduct(e))}
                />
            </div>
            {product.codes && <div className='contentSection'>
                <div className='contentSectionTitle'>Symbols</div> 
                
                <DataSourceCodes product={product} canEdit={canEdit} dataSources={dataSources} />
            </div>}
            {product.updateRules && <div className='contentSection'>
                <div className='contentSectionTitle'>Update Rules</div> 
                <UpdateRules product={product} canEdit={canEdit} dataSources={dataSources} products={products} />
            </div>}
            {product.curves && <div className='contentSection'>
                <div className='contentSectionTitle'>Curves</div> 
                <Curves product={product} dispatch={dispatch} canEdit={canEdit} users={users} showModal={showModal} setShowModal={setShowModal} />    
            </div>}
            <DeleteModal 
                deleteMode={showDeleteModal}
                setDeleteMode={setShowDeleteModal}
                onDelete={() => {
                    dispatch(CommodityProductActions.Products.deleteProduct(product.id))
                    setShowDeleteModal(false)
                }}
                warningMessage={"Delete product " + pageName + '?'}
            />
        </>}
        prevPage={{to: '/market/commodityProducts', text: 'Back to Products'}}
        componentProps={{canEdit, product, setProduct, productId }}
        actions={[
            {
                onClick: () => setShowModal(true),
                children: 'Upload curve',
                disabled: !canEdit
            },
            // {
            //     children: 'Delete product',
            //     disabled: !canEdit,
            //     buttonType: 'dangerButton',
            //     onClick: () => setShowDeleteModal(true)
            // }
        ]}
        widescreen={true}
        includeAddButton={false}        
    />
}
