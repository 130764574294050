import Home from '../modules/home'
import Login from '../modules/login'

import MarketRoutes from './market'
import MoveToBillRoutes from './moveToBill'
import ReportingRoutes from './reporting'
import SettingRoutes from './settings'


const routes = [
    {
        path: "",
        component: Home,
    },
    {
        path: "login",
        component: Login,
    },
    MarketRoutes,
    MoveToBillRoutes,
    ReportingRoutes,
    SettingRoutes
]

export default routes