import './button.scss';


export default function Button({
    buttonType='primaryButton',
    className,
    onClick,
    type='button',
    children,
    disabled=false
}){
    return <button 
        disabled={disabled}
        onClick={disabled ? null : onClick} 
        type={type} 
        style={{cursor: disabled ? "not-allowed" : "pointer"}}
        className={['actionButton', className, disabled ? "disabled" : buttonType].join(' ')}
    >
        {children}
    </button>
}